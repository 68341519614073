export interface ITasksResponse {
  count: number;
  next: null;
  previous: null;
  results: ITask[];
}

export interface ITask {
  id: number;
  project: number;
  title: string;
  description: string;
  task_status: string;
  time_when_read_client: null;
  time_during_which_considered_the_problem: null;
  pm_start_time: null;
  client_start_time: null;
  pm_total_time: string;
  client_total_time: string;
  archive: boolean;
  read_by_client: boolean;
  history: ITaskHistory[];
  file_paths: string[];
  reject_reason: string;
  created_at: string;
}

export interface ICreateTaskData {
  title: string;
  description: string;
}

export interface IUpdateTaskData {
  title?: string;
  description?: string;
  task_status?: string;
  reject_reason?: string;
  archive?: boolean;
}

export interface ITaskHistory {
  from_status: string;
  to_status: string;
  user: string;
  reject_reason: null;
  date: string;
}

export const taskStatuses = [
  {
    value: "new",
    label: "new",
    status: "default",
  },
  {
    value: "send_for_confirmation",
    label: "send_for_confirmation",
    status: "pending",
  },
  {
    value: "confirmed",
    label: "confirmed",
    status: "success",
  },
  {
    value: "reject",
    label: "rejected",
    status: "rejected",
  },
  {
    value: "in_developing",
    label: "in_developing",
    status: "in_developing",
  },
];

export const adminTaskStatuses = [
  {
    value: "new",
    label: "new",
    status: "default",
  },

  {
    value: "reject",
    label: "rejected",
    status: "rejected",
  },
  {
    value: "in_developing",
    label: "in_developing",
    status: "pending",
  },
];

export const adminTaskStatusesForSelect = [
  {
    value: "new",
    label: "new",
    status: "default",
  },

  {
    value: "send_for_confirmation",
    label: "send_for_confirmation",
    status: "pending",
  },
  {
    value: "in_developing",
    label: "in_developing",
    status: "pending",
  },
];

export const clientTaskStatuses = [
  {
    value: "confirmed",
    label: "confirmed",
    status: "success",
  },
  {
    value: "send_for_confirmation",
    label: "send_for_confirmation",
    status: "pending",
  },
];

export interface IConversionType {
  total_project_time: number;
  pm_total_time: number;
  client_total_time: number;
  pm_percentage: number;
  client_percentage: number;
}
