import SidebarLogo from "@/components/common/sidebar-logo/sidebar-logo";
import SidebarMenu from "@/components/common/sidebar-menu/sidebar-menu";
import SidebarProfile from "@/components/common/sidebar-profile/sidebar-profile";
import SidebarToggler from "@/components/common/sidebar-toggler/sidebar-toggler";
import { useAppDispatch } from "@/hooks/store-hooks";
import useMediaQuery from "@/hooks/use-media-query";
import useSidebarCollapse from "@/hooks/use-sidebar-collapse";
import { toggleSidebar } from "@/store/ui/ui.slice";
import classnames from "classnames";
import { useEffect } from "react";
import styles from "./sidebar.module.scss";

const Sidebar = () => {
  const sidebarCollapseStyles = useSidebarCollapse();
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    if (isTablet) {
      dispatch(toggleSidebar(!isTablet));
    }
  }, [isTablet]);

  return (
    <div
      className={classnames(styles.sidebar, "z-50")}
      style={sidebarCollapseStyles}
    >
      <SidebarToggler />
      <SidebarLogo />
      <div className={styles.sidebarMenuContent}>
        <SidebarMenu />
      </div>
      <SidebarProfile />
    </div>
  );
};

export default Sidebar;
