import TableSkeleton from "@/components/common/table-skeleton/table-skeleton";
import { useAppSelector } from "@/hooks/store-hooks";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImFilesEmpty } from "react-icons/im";
import { ITask } from "../../types";
import TaskItem from "../task-item/task-item";
import TasksFilterPopover from "../tasks-filter";
import styles from "./tasks-column.module.scss";

interface Props {
  isClient?: boolean;
  isArchive?: boolean;
}

const TasksColumn: FC<Props> = ({ isClient, isArchive }) => {
  const {
    adminTasks,
    isAdminTasksLoading,
    clientTasks,
    clientArchiveTasks,
    adminArchiveTasks,
    isClientTasksLoading,
  } = useAppSelector((state) => state.tasks);
  const { t } = useTranslation();

  const [tasks, setTasks] = useState<ITask[]>([]);

  useEffect(() => {
    const client = !isArchive ? clientTasks : clientArchiveTasks;
    const admin = !isArchive ? adminTasks : adminArchiveTasks;
    if (isClient) {
      setTasks(client);
    } else {
      setTasks(admin);
    }
  }, [
    isClient,
    adminTasks,
    clientTasks,
    isArchive,
    clientArchiveTasks,
    adminArchiveTasks,
  ]);

  return (
    <section
      className={classNames(
        styles.tasksColumn,
        isClient && "border-t-green-500",
        isClient ? "five-step" : "four-step",
      )}
    >
      <header className="mb-4 flex items-center justify-between">
        <h2>{isClient ? t("client_tasks") : t("musait_tasks")}</h2>

        {!isArchive && <TasksFilterPopover isClient={isClient} />}
      </header>

      <div className="flex min-h-[300px] flex-col gap-2">
        {(isClient ? isClientTasksLoading : isAdminTasksLoading) ? (
          <TableSkeleton height="300px" />
        ) : tasks.length ? (
          tasks.map((item, index) => (
            <TaskItem
              index={index}
              isArchive={isArchive}
              isClient={isClient}
              {...item}
              key={item.id}
            />
          ))
        ) : (
          <div className="flex h-[300px] w-full flex-col items-center justify-center">
            <ImFilesEmpty className="mb-2 text-4xl text-gray-300" />
            <h2 className="text-lg text-gray-300">{t("tasks_not_found")}</h2>
          </div>
        )}
      </div>
    </section>
  );
};

export default TasksColumn;
