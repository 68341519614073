import SelectableStatus from "@/components/common/selectable-status/selectable-status";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import parser from "html-react-parser";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  IProjectStatusType,
  projectStageStatusArray,
  projectStatuses,
} from "../lib/data/project.statuses";
import { updateProjectAction } from "../store/projects.actions";
import {
  setProjectsDrawerContent,
  setUpdatingProjectField,
  setUpdatingProjectId,
  toggleProjectsDrawer,
} from "../store/projects.slice";
import { IProject } from "../types";

const useProjectsTableData = (data: IProject[], isDetail?: boolean) => {
  const dispatch = useAppDispatch();
  const { isUpdatingProject, updatingProjectId, updatingProjectField } =
    useAppSelector((state) => state.projects);
  const { role } = useAuth();
  const { t } = useTranslation();

  // change project status
  const changeProjectStatus = (status: string, id: number | string) => {
    dispatch(setUpdatingProjectId(id));
    dispatch(setUpdatingProjectField("project_status"));
    dispatch(
      updateProjectAction({
        data: { project_status: status },
        id: id,
        role,
      }),
    );
  };

  // change project stage
  const changeProjectStage = (stage: string, id: number | string) => {
    dispatch(setUpdatingProjectId(id));
    dispatch(setUpdatingProjectField("project_stage"));
    dispatch(
      updateProjectAction({
        data: { project_stage: stage },
        id: id,
        role,
      }),
    );
  };

  return data?.map((item) => ({
    id: item.id,
    title: (
      <div>
        <h2 className="whitespace-nowrap text-[14px] font-medium">
          {item.title}
        </h2>
        {!isDetail &&
          item?.project_type_ids?.map((type, index) => (
            <span className="font-normal text-grayText">
              {index > 0 && ", "}
              {type.title}
            </span>
          ))}
      </div>
    ),
    project_type: item?.project_type_ids?.map((type, index) => (
      <span className="font-normal text-grayText">
        {index > 0 && ", "}
        {type.title}
      </span>
    )),
    project_manager: (
      <span className="text-[14px] font-medium">
        {item?.project_manager?.full_name}
      </span>
    ),
    client: (
      <span className="text-[14px] font-medium">{item?.client?.full_name}</span>
    ),
    deadline: (
      <div>
        <h2 className="whitespace-nowrap text-[14px] font-medium">
          {moment(item.start_date).format("MM-DD")} - {item.end_date}
        </h2>
        {item.is_overdue && (
          <span className="whitespace-nowrap text-[14px] font-medium text-red-500">
            {item.overdue_days} {t("days_after")}
          </span>
        )}
      </div>
    ),
    project_stage: (
      <SelectableStatus
        isLoading={
          isUpdatingProject &&
          updatingProjectId === item.id &&
          updatingProjectField === "project_stage"
        }
        text={
          projectStageStatusArray.find((x) => x.value === item.project_stage)
            ?.label!
        }
        status="default"
        onChange={(data) => changeProjectStage(data, item.id)}
        menuItems={projectStageStatusArray.map(
          // @ts-ignore
          (item) => ({
            value: item.value,
            label: item.label,
          }),
        )}
      />
    ),
    project_status: (
      <SelectableStatus
        isLoading={
          isUpdatingProject &&
          updatingProjectId === item.id &&
          updatingProjectField === "project_status"
        }
        text={projectStatuses[item.project_status].text}
        status={projectStatuses[item.project_status].status}
        onChange={(data) => {
          if (data === "in_pause") {
            dispatch(toggleProjectsDrawer(true));
            dispatch(setUpdatingProjectId(item.id));
            dispatch(setProjectsDrawerContent("pause_reason"));
          } else {
            changeProjectStatus(data, item.id);
          }
        }}
        menuItems={Object.keys(projectStatuses).map(
          // @ts-ignore
          (item: keyof IProjectStatusType) => ({
            value: item,
            label: projectStatuses[item].text,
          }),
        )}
      />
    ),
    file_paths: item?.file_paths,
    pause_reason: (
      <span className="text-md font-medium">
        {parser(item.pause_reason || "-- --")}
      </span>
    ),
  }));
};

export default useProjectsTableData;
