import { LogoCropedPng, LogoPng } from "@/assets";
import { useAppSelector } from "@/hooks/store-hooks";
import { Link } from "react-router-dom";

const SidebarLogo = () => {
  const { sidebarIsOpen } = useAppSelector((state) => state.ui);

  return (
    <Link to={"/"}>
      {sidebarIsOpen ? (
        <img
          className="mb-[32px] h-[32px] w-[140px] object-contain px-2"
          src={LogoPng}
          alt="logo png"
        />
      ) : (
        <img
          className="mb-[32px] h-[40px] w-full object-contain"
          src={LogoCropedPng}
          alt="logo png"
        />
      )}
    </Link>
  );
};

export default SidebarLogo;
