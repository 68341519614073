import { FC } from "react";
import styles from "./layout.module.scss";
import Sidebar from "./sidebar/sidebar";

type Props = {
  children: JSX.Element;
};

const LayoutApp: FC<Props> = ({ children }) => {
  return (
    <div className={styles.layoutAppWrapper}>
      <Sidebar />
      <div
        className={(styles.layoutMainContent, "ml-auto")}
        style={{ width: `calc(100% - 100px)`, minHeight: "100vh" }}
      >
        {children}
      </div>
    </div>
  );
};

export default LayoutApp;

export const withAppLayout = (Component: FC) => {
  const L = (props: any) => {
    return (
      <LayoutApp>
        <Component {...props} />
      </LayoutApp>
    );
  };

  return L;
};
