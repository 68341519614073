import { TransferToRightSvg } from "@/assets";
import SelectableStatus, {
  IStatusTypes,
} from "@/components/common/selectable-status/selectable-status";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { ITask, taskStatuses } from "../../types";

interface Props {
  task: ITask;
}

export function TastStatusHistories({ task }: Props) {
  const { t } = useTranslation();

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>{t("history")}</AccordionTrigger>
        <AccordionContent className="max-h-[400px] overflow-y-auto">
          {task?.history?.length ? (
            task?.history?.map((item) => (
              <div className="grid gap-2 border-t border-dashed border-t-border py-[20px]">
                <div className="flex items-center justify-between">
                  <h2 className="text-sm font-medium"> {item.user}</h2>

                  <span className="text-sm font-light text-grayText">
                    {item.date}
                  </span>
                </div>

                <div className="flex items-center gap-[14px]">
                  <SelectableStatus
                    readonly
                    status={
                      taskStatuses.find((x) => x.value === item?.from_status)
                        ?.status as IStatusTypes
                    }
                    text={
                      taskStatuses.find((x) => x.value === item?.from_status)
                        ?.label as IStatusTypes
                    }
                    menuItems={taskStatuses}
                  />

                  <img src={TransferToRightSvg} />

                  <SelectableStatus
                    readonly
                    status={
                      taskStatuses.find((x) => x.value === item?.to_status)
                        ?.status as IStatusTypes
                    }
                    text={
                      taskStatuses.find((x) => x.value === item?.to_status)
                        ?.label as IStatusTypes
                    }
                    menuItems={taskStatuses}
                  />
                </div>
                {item.reject_reason && (
                  <p className="rounded-md bg-gray-100 px-[14px] py-[10px]">
                    {parser(item.reject_reason || "")}
                  </p>
                )}
              </div>
            ))
          ) : (
            <h2 className="text-gray-400">Истории не найдены!</h2>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
