import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../types/users.types";
import {
  createUserAction,
  getUsersAction,
  updateeUserAction,
} from "./users.actions";

interface IUserInitialState {
  users: IUser[];
  isUsersLoading: boolean;
  isCreatingUser: boolean;
  search?: string;
  page?: number;
  totalCount?: number;
}

const initialState: IUserInitialState = {
  users: [],
  isUsersLoading: false,
  isCreatingUser: false,
  search: "",
  page: 1,
  totalCount: 10,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setUserPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAction.pending, (state) => {
        state.isUsersLoading = true;
      })
      .addCase(getUsersAction.fulfilled, (state, action) => {
        state.users = action.payload.results;
        state.totalCount = action.payload.count;
        state.isUsersLoading = false;
      })
      .addCase(getUsersAction.rejected, (state) => {
        state.isUsersLoading = false;
      })
      .addCase(createUserAction.pending, (state) => {
        state.isCreatingUser = true;
      })
      .addCase(createUserAction.fulfilled, (state) => {
        state.isCreatingUser = false;
      })
      .addCase(createUserAction.rejected, (state) => {
        state.isCreatingUser = false;
      })
      .addCase(updateeUserAction.pending, (state) => {
        state.isCreatingUser = true;
      })
      .addCase(updateeUserAction.fulfilled, (state) => {
        state.isCreatingUser = false;
      })
      .addCase(updateeUserAction.rejected, (state) => {
        state.isCreatingUser = false;
      });
  },
});

export const { setUserSearch, setUserPage } = userSlice.actions;
export default userSlice.reducer;
