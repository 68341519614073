import { fileUploadUrl } from "@/api/api.constants";
import axiosInstance from "@/api/api.interceptor";

export const uploadImages = async (files: FileList) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  const res = await axiosInstance.post(fileUploadUrl(), formData);

  return res.data;
};
