import { useAuth } from "@/features/auth";
import { refreshTokenAction } from "@/features/auth/store/auth.action";
import { useAppDispatch } from "@/hooks/store-hooks";
import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { commonRoutes } from "./common";
import { protectedRoutes } from "./protected";

export const AppRoutes = () => {
  const { accessToken, role } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshTokenAction());
  }, []);

  // user from store, authorization validate
  const user = accessToken;

  // combine all routes
  const routes = [
    ...commonRoutes({ user }),
    ...protectedRoutes({ user, role }),
  ];

  // create element
  const element = useRoutes(routes);

  return element;
};
