import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-tailwindcss-select";

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  onChange: (values: string[]) => void;
  options?: OptionType[];
  value?: any[];
}

const MultiSelect: FC<Props> = ({ options, onChange, value = [] }) => {
  const [localValue, setLocalValue] = useState<OptionType[]>();
  const { t } = useTranslation();
  const handleChange = (_value: any = []) => {
    console.log("value:", _value);
    setLocalValue(_value);
    onChange(_value?.map((item: OptionType) => item.value));
  };

  useEffect(() => {
    if (value?.length) {
      setLocalValue(options?.filter((item) => value.includes(item.value)));
    }
  }, [value]);

  return (
    <Select
      isMultiple
      primaryColor="primary"
      value={localValue!}
      onChange={handleChange}
      options={
        options?.map((item) => ({
          ...item,
          label: t(item.label),
        }))!
      }
    />
  );
};

export default MultiSelect;
