import CustomSelect from "@/components/common/custom-select/custom-select";
import { DateRangePicker } from "@/components/common/date-range-picker/date-range-picker";
import ImageUploader from "@/components/common/image-uploader/image-uploader";
import MultiSelect from "@/components/common/multi-select/multi-select";
import RichTextEditor from "@/components/common/rich-text-editor/rich-text-editor";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useDebounce } from "@/hooks/use-debounce";
import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import {
  projectStageStatusArray,
  projectStatusesArray,
} from "../../lib/data/project.statuses";
import { updateProjectSchema } from "../../lib/validators/projects.validator";
import {
  getAllProjectsAction,
  getClients,
  getProjectManagers,
  getProjectTypes,
  updateProjectAction,
} from "../../store/projects.actions";
import { setProjectsDrawerContent } from "../../store/projects.slice";
import { IProject } from "../../types";

interface Props {
  initialState?: IProject;
  onCompleted?: () => void;
  errors?: any[];
}

export function UpdateProjectForm({
  initialState,
  errors,
  onCompleted,
}: Props) {
  const [searchPM] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const {
    isCreatingProject,
    clients,
    projectManagers,
    projectTypes,
    projects,
    isUpdatingProject,
  } = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const [_, setProject] = useState<IProject>();
  const [projectStatus, setProjectStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  const debouncedSearchPM = useDebounce(searchPM, 300);
  const debouncedSearchClient = useDebounce(searchClient, 300);
  const { toast } = useToast();
  const { role } = useAuth();

  const { t } = useTranslation();

  const form = useForm<z.infer<typeof updateProjectSchema>>({
    resolver: zodResolver(updateProjectSchema),
  });

  function onSubmit(values: any) {
    dispatch(
      updateProjectAction({ data: values, id: initialState?.id!, role }),
    ).then((res) => {
      if (res.type === "projects/update/fulfilled") {
        toast({
          title: t("project_updated"),
          className: "text-white bg-green-500",
        });
        dispatch(getAllProjectsAction({}));
        onCompleted?.();
      } else {
        toast({
          title: t("something_went_wrong"),
          className: "text-white bg-red-500",
        });
      }
    });
  }

  function setError() {
    if (errors?.length) {
      errors.map((item: any) => {
        form.setError(item, {
          message: "The username already exist!",
        });
      });
    }
  }

  useEffect(() => {
    setError();
  }, [errors]);

  useEffect(() => {
    if (initialState) {
      projects.map((item) => {
        if (item.id === initialState.id) {
          setProject(item);
          setProjectStatus(item?.project_status);
          form.reset({
            title: item?.title,
            project_type_ids: item?.project_type_ids?.map((item) =>
              String(item.id),
            ),
            start_date: item?.start_date,
            end_date: item?.end_date,
            description: item?.description,
            project_manager: item?.project_manager?.id?.toString(),
            client: item?.client?.id?.toString(),
            project_status: item?.project_status,
            project_stage: item?.project_stage,
            file_paths: item?.file_paths || [],
            pause_reason: item?.pause_reason || "",
          });
        }
      });
    }
  }, [initialState]);

  useEffect(() => {
    dispatch(getClients({ search: debouncedSearchClient, role })).finally(
      () => {
        setRefresh(!refresh);
      },
    );
  }, [debouncedSearchClient]);

  useEffect(() => {
    dispatch(getProjectManagers({ search: debouncedSearchPM })).finally(() => {
      setRefresh(!refresh);
    });
  }, [debouncedSearchPM]);

  useEffect(() => {
    dispatch(getProjectTypes());
  }, []);

  const openViewContent = () => {
    dispatch(setProjectsDrawerContent("view"));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_name")}</FormLabel>
              <FormControl>
                <Input placeholder={t("project_name")} {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="project_type_ids"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_type")}</FormLabel>
              <FormControl>
                <MultiSelect
                  onChange={(data) => form.setValue("project_type_ids", data)}
                  value={field.value}
                  options={projectTypes.map((item) => ({
                    label: item.title,
                    value: String(item.id),
                  }))}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="project_stage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_stage")}</FormLabel>
              <FormControl>
                <CustomSelect
                  primaryColor="primary"
                  isSearchable={false}
                  onChange={(data) => form.setValue("project_stage", data)}
                  value={field.value}
                  options={projectStageStatusArray.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="project_status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("status")}</FormLabel>
              <FormControl>
                <CustomSelect
                  isSearchable={false}
                  onChange={(data) => {
                    form.setValue("project_status", data);
                    setProjectStatus(data);
                  }}
                  value={field.value}
                  options={projectStatusesArray.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  primaryColor="primary"
                />
              </FormControl>
            </FormItem>
          )}
        />

        {role === "admin" && (
          <FormField
            control={form.control}
            name="project_manager"
            render={() => (
              <FormItem>
                <FormLabel>{t("project_manager")}</FormLabel>
                <FormControl>
                  <CustomSelect
                    primaryColor="primary"
                    onChange={(data: any) =>
                      form.setValue("project_manager", data)
                    }
                    value={form.getValues("project_manager")}
                    options={projectManagers.map((item) => ({
                      label: item.full_name,
                      value: String(item.id),
                    }))}
                    loadOptions={(e: any) => {
                      setSearchClient(e);
                      return Promise.resolve(
                        clients.map((item) => ({
                          label: item.full_name,
                          value: String(item.id),
                        })),
                      );
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="client"
          render={() => (
            <FormItem>
              <FormLabel>{t("client")}</FormLabel>
              <FormControl>
                <CustomSelect
                  refreshList={refresh}
                  primaryColor="primary"
                  onChange={(data: any) => form.setValue("client", data)}
                  value={form.getValues("client")}
                  options={clients.map((item) => ({
                    label: item.full_name,
                    value: String(item.id),
                  }))}
                  loadOptions={(e: any) => {
                    setSearchClient(e);
                    return Promise.resolve(
                      clients.map((item) => ({
                        label: item.full_name,
                        value: String(item.id),
                      })),
                    );
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="start_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_deadline")}</FormLabel>
              <FormControl>
                <DateRangePicker
                  {...field}
                  value={[
                    form.getValues("start_date")!,
                    form.getValues("end_date")!,
                  ]}
                  setValue={(data) => {
                    form.setValue(
                      "start_date",
                      moment(data[0]).format("YYYY-MM-DD"),
                    );
                    form.setValue(
                      "end_date",
                      moment(data[1]).format("YYYY-MM-DD"),
                    );
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("comment")}</FormLabel>
              <FormControl>
                <RichTextEditor
                  setValue={(data) => form.setValue("description", data)}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="file_paths"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("file_upload")}</FormLabel>
              <FormControl>
                <ImageUploader
                  images={field.value}
                  onChange={(images) => form.setValue("file_paths", images)}
                  initialImages={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {projectStatus === "in_pause" ? (
          <FormField
            control={form.control}
            name="pause_reason"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("pause_reason")} </FormLabel>
                <FormControl>
                  <RichTextEditor
                    setValue={(data) => form.setValue("pause_reason", data)}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ) : null}

        <div className="flex gap-2">
          <Button
            isLoading={isCreatingProject}
            className="w-full"
            type="button"
            onClick={openViewContent}
            variant={"outline"}
          >
            {t("cancel")}
          </Button>
          <Button
            isLoading={isCreatingProject || isUpdatingProject}
            className="w-full"
            type="submit"
          >
            {t("save")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
