import { InfoSvg, TrashSvg } from "@/assets";
import { TablePagination } from "@/components/common/pagination/pagination";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { DialogTitle } from "@/components/ui/dialog";
import {
  DrawerDialog,
  DrawerDialogContent,
  DrawerDialogHeader,
} from "@/components/ui/drawer-simple";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useProjectsTableData from "../hooks/useProjectsTableData";
import { archiveProjectAction } from "../store/projects.actions";
import {
  setDeleteProjectId,
  setProjectPage,
  setUpdatingProjectId,
  toggleProjectsDrawer,
} from "../store/projects.slice";
import { IProject } from "../types";
import { ITableColumn } from "./columns";
import { UpdateProjectForm } from "./manage-project/update-project-form";
import ProjectDetails from "./project-details/project-details";
interface DataTableProps {
  columns: ITableColumn[];
  data: IProject[];
}

export function ProjectsTable({ columns, data }: DataTableProps) {
  const { drawerContent, projectsDrawerIsOpen, totalCount, page } =
    useAppSelector((state) => state.projects);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const tableData = useProjectsTableData(data);
  const dispatch = useAppDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { role } = useAuth();
  const { t } = useTranslation();

  const changePage = (page: number) => {
    dispatch(setProjectPage(page));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // delete project
  const deleteHandler = () => {
    dispatch(setDeleteProjectId(deleteId!));
    dispatch(archiveProjectAction({ id: deleteId!, role })).then((res) => {
      if (res.type === "projects/archive/fulfilled") {
        toast({
          title: t("project_archived"),
          className: "bg-green-500 text-white",
        });
      }
    });
  };

  return (
    <div
      className="overflow-y-auto bg-white border rounded-md shadow-sm"
      style={{ height: "calc(100vh - 230px)" }}
    >
      <Table>
        <TableHeader className="sticky top-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : t(
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          ) as string,
                        )}
                  </TableHead>
                );
              })}
              <TableHead></TableHead>
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            tableData.map((row: any) => (
              <TableRow
                className="cursor-pointer"
                key={row.id}
                data-name="td"
                onClick={(e: any) => {
                  navigate(`/project/${row.id}`);
                  console.log(e.target);
                }}
              >
                {columns?.map((cell) => (
                  <TableCell key={cell?.accessorKey}>
                    {row[cell?.accessorKey]}
                  </TableCell>
                ))}
                <TableCell className="flex justify-end">
                  <Button
                    className="px-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteId(row.id);
                      setDeleteDialog(true);
                    }}
                    variant={"ghost"}
                  >
                    <img className="min-h-[20px] min-w-[20px]" src={TrashSvg} />
                  </Button>
                  <Button
                    className="px-3"
                    variant={"ghost"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedProject(row);
                      dispatch(toggleProjectsDrawer(true));
                      dispatch(setUpdatingProjectId(row.id));
                    }}
                  >
                    <img className="min-h-[20px] min-w-[20px]" src={InfoSvg} />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t("no_results")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        current={page}
        totalCount={totalCount}
        setCurrent={(p) => changePage(p)}
      />

      <DrawerDialog
        open={projectsDrawerIsOpen}
        onOpenChange={(bool) => {
          if (!bool) {
            setSelectedProject(null);
            dispatch(toggleProjectsDrawer(false));
          }
        }}
      >
        <DrawerDialogContent>
          <DrawerDialogHeader>
            <DialogTitle>Проект</DialogTitle>
          </DrawerDialogHeader>
          <div
            className="overflow-y-auto px-[24px] pb-[24px]"
            style={{ height: "calc(100vh - 80px)" }}
          >
            {drawerContent === "update" ? (
              <UpdateProjectForm initialState={selectedProject!} />
            ) : (
              <ProjectDetails id={selectedProject?.id!} />
            )}
          </div>
        </DrawerDialogContent>
      </DrawerDialog>

      <AlertDialog open={deleteDialog} onOpenChange={(e) => setDeleteDialog(e)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("do_you_want_delete_project")}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setDeleteDialog(false)}>
              {t("cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => deleteHandler()}
              className="bg-red-500 hover:bg-red-600"
            >
              {t("yes")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
