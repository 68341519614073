import { XCloseSvg } from "@/assets";
import { Button } from "@/components/ui/button";
import { clsx } from "clsx";
import { FC } from "react";

const openClassNames = {
  right: "translate-x-0",
  left: "translate-x-0",
  top: "translate-y-0",
  bottom: "translate-y-0",
};

const closeClassNames = {
  right: "translate-x-full",
  left: "-translate-x-full",
  top: "-translate-y-full",
  bottom: "translate-y-full",
};

const classNames = {
  right: "inset-y-0 right-0 xs:w-[90%] md:w-[440px]",
  left: "inset-y-0 left-0",
  top: "inset-x-0 top-0",
  bottom: "inset-x-0 bottom-0",
};

interface Props {
  open: boolean;
  onClose: () => void;
  side?: string;
  title?: string;
  body?: () => JSX.Element;
}

const Drawer: FC<Props> = ({ open, onClose, side = "right", title, body }) => {
  return (
    <div
      id={`dialog-${side}`}
      className="relative z-10"
      aria-labelledby="slide-over"
      role="dialog"
      aria-modal="true"
      onClick={() => onClose()}
    >
      <div
        className={clsx(
          "fixed inset-0 bg-gray-500 bg-opacity-20 backdrop-blur-sm transition-all",
          {
            "visible opacity-100 duration-500 ease-in-out": open,
          },
          { "invisible opacity-0 duration-500 ease-in-out": !open },
        )}
      ></div>
      <div className={clsx({ "fixed inset-0 overflow-hidden": open })}>
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={clsx(
              "pointer-events-none fixed max-w-full",
              classNames.right,
            )}
          >
            <div
              className={clsx(
                "pointer-events-auto relative h-full w-full transform transition duration-500 ease-in-out",
                { [closeClassNames.right]: !open },
                { [openClassNames.right]: open },
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div
                className={clsx(
                  "flex h-full flex-col overflow-y-scroll border-l border-l-border bg-white shadow-xl",
                )}
              >
                <header className="flex h-[80px] min-h-[80px] w-full items-center justify-between border-b border-b-border bg-white px-[24px]">
                  <h2 className="text-[20px] font-semibold">{title}</h2>

                  <Button variant={"ghost"} onClick={onClose}>
                    <img src={XCloseSvg} alt="Close svg" />
                  </Button>
                </header>

                <div className="grid p-6">{body?.()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
