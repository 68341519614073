import {
  createProjectUrl,
  deleteProjectUrl,
  getArchiveProjectsUrl,
  getClientsUrl,
  getPmsUrl,
  getProjectsUrl,
  getProjectTypesUrl,
  updateProjectUrl,
} from "@/api/api.constants";
import axiosInstance from "@/api/api.interceptor";
import { IUserResponse } from "@/features/users/types/users.types";
import { IGetResponse } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateProject,
  IProject,
  IProjectType,
  IUpdateProject,
} from "../types";
import { FromToType } from "./projects.slice";

export const getAllProjectsAction = createAsyncThunk<
  IGetResponse<IProject>,
  {
    role?: string;
    page?: number;
    search?: string;
    fromTo?: FromToType;
    status?: string;
  }
>("projects/all", async ({ role, page, search, fromTo, status }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<IGetResponse<IProject>>(
      getProjectsUrl(role || "admin", page!, search, fromTo!, status!),
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getArchiveProjectsAction = createAsyncThunk<
  IGetResponse<IProject>,
  { role: string; page?: number }
>("projects/archive/all", async ({ role, page }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<IGetResponse<IProject>>(
      getArchiveProjectsUrl(role, page!),
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getProjectTypes = createAsyncThunk<IGetResponse<IProjectType>>(
  "projects/types",
  async (_, thunkAPI) => {
    try {
      const res =
        await axiosInstance.get<IGetResponse<IProjectType>>(
          getProjectTypesUrl(),
        );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createProjectAction = createAsyncThunk<
  IProject,
  { data: ICreateProject; role?: string }
>("projects/create", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(createProjectUrl(data.role), {
      ...data.data,
    });
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getProjectManagers = createAsyncThunk<
  IUserResponse,
  { search: string }
>("projects/pm", async ({ search }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<IUserResponse>(getPmsUrl(search, 1));
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getClients = createAsyncThunk<
  IUserResponse,
  { search: string; role: string }
>("projects/clients", async ({ search, role }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<IUserResponse>(
      getClientsUrl(search, 1, role),
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateProjectAction = createAsyncThunk<
  IProject,
  { data: IUpdateProject; id: string | number; role: string }
>("projects/update", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.patch(
      updateProjectUrl(data.id, data.role),
      {
        ...data.data,
      },
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteProjectAction = createAsyncThunk<
  IProject,
  { id: string | number; role: string }
>("projects/delete", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.delete(
      deleteProjectUrl(data.id, data.role),
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const archiveProjectAction = createAsyncThunk<
  IProject,
  { id: string | number; role: string }
>("projects/archive", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.patch(
      updateProjectUrl(data.id, data.role),
      {
        archive: true,
      },
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const restoreProjectAction = createAsyncThunk<
  IProject,
  { id: string | number; role: string }
>("projects/restore", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.patch(
      updateProjectUrl(data.id, data.role),
      {
        archive: false,
      },
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
