import { z } from "zod";

export const createTaskSchema = z.object({
  title: z.string(),
  description: z.string(),
  file_paths: z.array(z.string()).optional(),
});

export const updateTaskSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  task_status: z.string().optional(),
  file_paths: z.array(z.string()).optional(),
});
