import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useStatusStyles from "@/hooks/use-status-styles";
import { ReloadIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import styles from "./selectable-status.module.scss";

type IMenuItem = {
  label: string;
  value: string;
};

export type IStatusTypes =
  | "pending"
  | "default"
  | "rejected"
  | "success"
  | "in_developing";

interface Props {
  status: IStatusTypes;
  text: string;
  menuItems?: IMenuItem[];
  onChange?: (status: string) => void;
  isLoading?: boolean;
  readonly?: boolean;
}

const SelectableStatus: FC<Props> = ({
  status,
  text,
  menuItems,
  onChange,
  isLoading,
  readonly = false,
}) => {
  const [_, setValue] = useState(status);
  const classes = useStatusStyles(status);
  const { t } = useTranslation();

  const selectItem = (item: IStatusTypes) => {
    setValue(item);
    onChange?.(item);
  };

  if (readonly) {
    return (
      <div className={classNames(styles.selectableStatus, classes)}>
        <span className="whitespace-nowrap px-1">{t(text)}</span>
        {isLoading ? <ReloadIcon className="h-4 w-4 animate-spin" /> : null}
      </div>
    );
  }

  return (
    <div className="min-w-[150px]">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <button className={classNames(styles.selectableStatus, classes)}>
            <span className="whitespace-nowrap px-1 text-left text-xs">
              {t(text)}
            </span>
            {isLoading ? (
              <ReloadIcon className="h-4 w-4 animate-spin" />
            ) : (
              <i>
                <BiChevronDown />
              </i>
            )}
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {menuItems?.map((key) => (
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                selectItem(key.value as IStatusTypes);
              }}
              key={key.value}
            >
              {t(key.label)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default SelectableStatus;
