export function createLoginAndPassword(
  phoneNumber?: string,
  fullName?: string,
): { login: string; password: string } {
  function generateRandomString(length: number): string {
    const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const phone = phoneNumber
    ? phoneNumber.replace(/[^0-9]/g, "")
    : generateRandomString(10);
  const name = fullName
    ? fullName.replace(/[^a-zA-Z0-9\s]/g, "").toLowerCase()
    : generateRandomString(8);

  const login =
    name.replace(/\s+/g, ".").length > 1
      ? name.replace(/\s+/g, ".")
      : generateRandomString(8);

  const password = `${phone.slice(phone.length - 4, phone.length)}${name.replace(/\s+/g, "")}`;

  return { login, password };
}
