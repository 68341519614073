import classNames from "classnames";
import { FC } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  photoUrl: string;
}

const PhotoViewer: FC<Props> = ({ isOpen, onClose, photoUrl }) => {
  return (
    <div
      data-state={isOpen ? "open" : "closed"}
      className={classNames(
        "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      )}
    >
      <button
        onClick={onClose}
        className="absolute right-2 top-2 z-50 rounded-full p-2 text-4xl text-white focus:outline-none"
      >
        &times;
      </button>
      <div className="relative rounded-lg bg-white shadow-lg">
        <img src={photoUrl} alt="Photo" className="rounded-lg" />
      </div>
    </div>
  );
};

export default PhotoViewer;
