import AddButton from "@/components/common/add-button/add-button";
import PageTitle from "@/components/common/page-title/page-title";
import SearchBar from "@/components/common/search-bar/search-bar";
import { useAppDispatch } from "@/hooks/store-hooks";
import { useDebounce } from "@/hooks/use-debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setProjectsSearch } from "../store/projects.slice";
import { CreateProjectForm } from "./manage-project/create-project-form";
import ProjectsFilter from "./projects-filter/projects-filter";

const ProjectsPageHeader = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const dispatch = useAppDispatch();

  const creatingCompleted = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    dispatch(setProjectsSearch(debouncedSearch));
  }, [debouncedSearch]);

  return (
    <div className="flex flex-wrap justify-between gap-4 xs:flex-col xs:items-start lg:flex-row lg:items-center">
      <PageTitle title={t("projects")} isBack={false} />
      <div className="flex items-stretch gap-3 xs:w-full xs:flex-col lg:w-auto lg:flex-row">
        <SearchBar onChange={setSearch} />
        <ProjectsFilter />
        <AddButton
          text={t("create_proejct")}
          title={t("create_proejct")}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          formBody={() => <CreateProjectForm onCompleted={creatingCompleted} />}
        />
      </div>
    </div>
  );
};

export default ProjectsPageHeader;
