import { useAuth } from "@/features/auth";
import { useAppSelector } from "@/hooks/store-hooks";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import classNames from "classnames";
import { ChevronRightIcon } from "lucide-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export interface IMenuItemProps {
  icon: string;
  title: string;
  path: string;
  subitems?: IMenuItemProps[];
  roles: string[];
}

const MenuItem: FC<IMenuItemProps> = ({
  icon,
  title,
  path,
  subitems,
  roles,
}) => {
  const { sidebarIsOpen } = useAppSelector((state) => state.ui);
  const { projects } = useAppSelector((state) => state.projects);
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const { role } = useAuth();
  const { t } = useTranslation();

  if (!roles.includes(role)) return;

  return (
    <li
      key={title}
      className="relative mb-2 list-none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!subitems?.length ? (
        <Link
          to={path}
          className={classNames(
            path === pathname ? "bg-gray-50" : "hover:bg-gray-50",
            "group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700",
            { "justify-center": !sidebarIsOpen },
          )}
        >
          <img src={icon} alt={`svg icon: ${title}`} className="h-6 w-6" />
          <span
            className={classNames(
              `flex transition-opacity duration-300`,
              sidebarIsOpen
                ? ""
                : "absolute right-[-150px] flex min-w-[120px] rounded-md bg-white px-[20px] py-2 text-left shadow-md",
              isHovered ? "flex" : sidebarIsOpen ? "" : "hidden",
            )}
          >
            {t(title)}
          </span>
        </Link>
      ) : (
        <Disclosure as="div">
          <DisclosureButton
            className={classNames(
              path === pathname ? "bg-gray-50" : "hover:bg-gray-50",
              "group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700",
              { "justify-center": !sidebarIsOpen },
            )}
          >
            <img src={icon} alt={`svg icon: ${title}`} className="h-6 w-6" />
            {sidebarIsOpen && (
              <span className="transition-opacity duration-300">
                {t(title)}
              </span>
            )}
            {sidebarIsOpen && (
              <ChevronRightIcon
                aria-hidden="true"
                className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
              />
            )}
          </DisclosureButton>
          {sidebarIsOpen ? (
            <DisclosurePanel as="ul" className="mt-1 px-2">
              {subitems.map((subItem) => (
                <li key={subItem.path}>
                  <Link
                    onClick={() => {}}
                    to={subItem.path}
                    className={classNames(
                      path === pathname ? "bg-gray-50" : "hover:bg-gray-50",
                      "block max-w-full overflow-ellipsis rounded-md py-2 pl-9 pr-2 text-[14px] font-medium leading-6 text-gray-700",
                    )}
                  >
                    {t(subItem.title)}
                  </Link>
                </li>
              ))}
              {projects.map((item) => (
                <li key={item.id}>
                  <Link
                    onClick={() => {}}
                    to={`/project/${item.id}`}
                    className={classNames(
                      `/project/${item.id}` === pathname
                        ? "bg-gray-50"
                        : "hover:bg-gray-50",
                      "overflow-ellipsisrounded-md block max-w-full py-2 pl-9 pr-2 text-[14px] font-medium leading-6 text-gray-700",
                    )}
                  >
                    {t(item.title)}
                  </Link>
                </li>
              ))}
            </DisclosurePanel>
          ) : (
            isHovered && (
              <ul className="absolute left-full top-0 z-50 mt-2 w-48 rounded-md bg-white shadow-lg transition-opacity duration-300">
                {subitems.map((subItem) => (
                  <li key={subItem.path}>
                    <Link
                      to={subItem.path}
                      className={classNames(
                        path === pathname ? "bg-gray-50" : "hover:bg-gray-50",
                        "block px-4 py-2 text-[16px] font-medium leading-6 text-gray-700",
                      )}
                    >
                      {t(subItem.title)}
                    </Link>
                  </li>
                ))}
                {projects.map((item) => (
                  <li key={item.id}>
                    <li key={item.id}>
                      <Link
                        to={`/project/${item.id}`}
                        className={classNames(
                          `/project/${item.id}` === pathname
                            ? "bg-gray-50"
                            : "hover:bg-gray-50",
                          "block px-4 py-2 text-[16px] font-medium leading-6 text-gray-700",
                        )}
                      >
                        {item.title}
                      </Link>
                    </li>
                  </li>
                ))}
              </ul>
            )
          )}
        </Disclosure>
      )}
    </li>
  );
};

export default MenuItem;
