import { storage } from "@/services/storage/storage.service";
import { createSlice } from "@reduxjs/toolkit";
import { loginAction, refreshTokenAction } from "./auth.action";

interface IAuthInitialState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthLoading: boolean;
  full_name: string;
  role: string;
}

const initialState: IAuthInitialState = {
  accessToken: null,
  refreshToken: null,
  isAuthLoading: false,
  full_name: "",
  role: "client",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      storage.clearToken();
      state.accessToken = null;
      state.refreshToken = null;
      state.role = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isAuthLoading = false;
        storage.saveToken(action.payload.access, action.payload.refresh);
        storage.saveUser(action.payload.full_name, action.payload.role);
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
        state.role = action.payload.role;
        state.full_name = action.payload.full_name;
      })
      .addCase(loginAction.rejected, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(refreshTokenAction.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(refreshTokenAction.fulfilled, (state, action) => {
        state.isAuthLoading = false;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
        state.role = action.payload.role;
        state.full_name = action.payload.full_name;
      })
      .addCase(refreshTokenAction.rejected, (state) => {
        state.isAuthLoading = false;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
