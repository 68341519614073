import { FC } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface Props {
  totalHours: number;
  spendHours: number;
  position: "company" | "client";
}

const CircularProgressBar: FC<Props> = ({
  totalHours,
  spendHours,
  position,
}) => {
  const primaryColor = "blue-500";
  const pathColor =
    position === "client" ? "rgba(166, 244, 197, 1)" : "rgba(178, 221, 255, 1)";

  return (
    <div className="relative rounded-full bg-red-300">
      <span className="absolute left-[50%] top-[50%] flex h-[10px] w-[10px] translate-x-[-50%] translate-y-[-50%] transform items-center justify-center rounded-full bg-white">
        <span
          className={`h-[6px] w-[6px] rounded-full bg-${primaryColor}`}
        ></span>
      </span>
      <CircularProgressbar
        className={`w-[120px] rounded-full border-[4px] border-${primaryColor} bg-white`}
        background={true}
        strokeWidth={50}
        value={totalHours === 0 ? 0 : (spendHours / totalHours) * 100}
        styles={buildStyles({
          strokeLinecap: "butt",
          backgroundColor: "#fff",
          pathColor: pathColor,
          textColor: "#fff",
          trailColor: "transparent",
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
