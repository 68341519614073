import Login from "@/pages/login/login";
import { Navigate } from "react-router-dom";

export const commonRoutes = ({ user }: { user: any }) => {
  return [
    {
      path: "/login",
      element: !user ? <Login /> : <Navigate to="/" replace />,
    },
  ];
};
