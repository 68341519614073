import SelectableStatus from "@/components/common/selectable-status/selectable-status";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  IProjectStatusType,
  projectStageStatus,
  projectStatuses,
} from "../lib/data/project.statuses";
import { updateProjectAction } from "../store/projects.actions";
import {
  setUpdatingProjectField,
  setUpdatingProjectId,
} from "../store/projects.slice";
import { IProject } from "../types";

const useArchiveProjectsTableData = (data: IProject[]) => {
  const dispatch = useAppDispatch();
  const { isUpdatingProject, updatingProjectId, updatingProjectField } =
    useAppSelector((state) => state.projects);
  const { role } = useAuth();

  const { t } = useTranslation();
  // change project status
  const changeProjectStatus = (status: string, id: number | string) => {
    dispatch(setUpdatingProjectId(id));
    dispatch(setUpdatingProjectField("project_status"));
    dispatch(
      updateProjectAction({
        data: { project_status: status },
        id: id,
        role,
      }),
    );
  };

  // change project stage
  const changeProjectStage = (stage: string, id: number | string) => {
    dispatch(setUpdatingProjectId(id));
    dispatch(setUpdatingProjectField("project_stage"));
    dispatch(
      updateProjectAction({
        data: { project_stage: stage },
        id: id,
        role,
      }),
    );
  };

  return data.map((item) => ({
    id: item.id,
    title: (
      <div>
        <h2 className="whitespace-nowrap text-[14px] font-medium">
          {item.title}
        </h2>
        {item?.project_type_ids?.map((type: any, index) => (
          <span className="font-normal text-grayText">
            {index > 0 && ", "}
            {type.title}
          </span>
        ))}
      </div>
    ),
    project_manager: (
      <span className="text-[14px] font-medium">
        {item?.project_manager?.full_name}
      </span>
    ),
    client: (
      <span className="text-[14px] font-medium">{item?.client?.full_name}</span>
    ),
    deadline: (
      <div>
        <h2 className="whitespace-nowrap text-[14px] font-medium">
          {moment(item.start_date).format("MM-DD")} - {item.end_date}
        </h2>
        {item.is_overdue && (
          <span className="text-[14px] font-medium text-red-500">
            {item.overdue_days} {t("days_after")}
          </span>
        )}
      </div>
    ),
    project_stage: (
      <SelectableStatus
        isLoading={
          isUpdatingProject &&
          updatingProjectId === item.id &&
          updatingProjectField === "project_stage"
        }
        text={projectStageStatus[item.project_stage]}
        status="default"
        onChange={(data) => changeProjectStage(data, item.id)}
        menuItems={Object.keys(projectStageStatus).map(
          // @ts-ignore
          (item: keyof IProjectStatusType) => ({
            value: item,
            label: projectStageStatus[item],
          }),
        )}
      />
    ),
    project_status: (
      <SelectableStatus
        isLoading={
          isUpdatingProject &&
          updatingProjectId === item.id &&
          updatingProjectField === "project_status"
        }
        text={projectStatuses[item.project_status].text}
        status={projectStatuses[item.project_status].status}
        onChange={(data) => changeProjectStatus(data, item.id)}
        menuItems={Object.keys(projectStatuses).map(
          // @ts-ignore
          (item: keyof IProjectStatusType) => ({
            value: item,
            label: projectStatuses[item].text,
          }),
        )}
      />
    ),
  }));
};

export default useArchiveProjectsTableData;
