import { ViewedSvg } from "@/assets";
import SelectableStatus, {
  IStatusTypes,
} from "@/components/common/selectable-status/selectable-status";
import { Button } from "@/components/ui/button";
import {
  DrawerDialog,
  DrawerDialogContent,
  DrawerDialogHeader,
} from "@/components/ui/drawer-simple";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { DialogTitle } from "@radix-ui/react-dialog";
import classNames from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getAdminArchiveTasks,
  getClientArchiveTasks,
  updateTaskAction,
} from "../../store/tasks.action";
import {
  setSelectedTaskId,
  setTasksDrawerContent,
} from "../../store/tasks.slice";
import { adminTaskStatusesForSelect, ITask, taskStatuses } from "../../types";
import { UpdateTaskForm } from "../manage-tasks/update-task-form";
import TaskDetails from "../task-details/task-details";
import { calculateTime } from "../task-statistics-item/task-statistics-item";
import styles from "./task-item.module.scss";

interface Props extends ITask {
  isClient?: boolean;
  isArchive?: boolean;
  index: number;
}

const TaskItem: FC<Props> = (task) => {
  const {
    title,
    task_status,
    time_when_read_client,
    isClient,
    pm_total_time,
    client_total_time,
    index,
  } = task;
  const { tasksDrawerContent } = useAppSelector((state) => state.tasks);
  const { role } = useAuth();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const params = useParams();
  const { t } = useTranslation();

  function changeTaskStatus(status: string) {
    dispatch(
      updateTaskAction({
        data: { task_status: status },
        task_id: task?.id!,
      }),
    ).then((res) => {
      if (res.type === "tasks/update/fulfilled") {
        toast({
          title: t("task_status_changed"),
          className: "bg-green-500 text-white",
        });
      }
    });
  }

  function onRestoreProject() {
    dispatch(
      updateTaskAction({
        data: { archive: false },
        task_id: task.id!,
      }),
    ).then((res) => {
      if (res.type === "tasks/update/fulfilled") {
        dispatch(
          getAdminArchiveTasks({
            project_id: params.id!,
            statuses: "new|in_developing|reject",
          }),
        );
        dispatch(
          getClientArchiveTasks({
            project_id: params.id!,
            statuses: "send_for_confirmation|confirmed",
          }),
        );
        toast({
          title: "Статус задачи изменился!",
          className: "bg-green-500 text-white",
        });
      }
    });
  }

  return (
    <>
      <div
        className={classNames(
          styles.taskItem,
          styles[task.task_status],
          !isClient && index === 0 && "six-step",
        )}
        onClick={() => {
          setDrawerIsOpen(true);
          dispatch(setSelectedTaskId(task?.id!));
        }}
      >
        <div className="flex flex-col items-start gap-1">
          <h2 className="text-[14px] font-medium">{title}</h2>
          {task.archive ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onRestoreProject();
              }}
              variant={"link"}
              size={"sm"}
              className="p-0 text-sm"
            >
              {t("restore")}
            </Button>
          ) : (
            <SelectableStatus
              readonly={isClient || role === "client"}
              status={
                taskStatuses.find((item) => item.value === task_status)
                  ?.status as IStatusTypes
              }
              text={
                taskStatuses.find((item) => item.value === task_status)
                  ?.label as IStatusTypes
              }
              menuItems={adminTaskStatusesForSelect}
              onChange={changeTaskStatus}
            />
          )}
        </div>

        <div className="flex flex-col items-end justify-end gap-1">
          <span className="flex gap-2 text-[14px]">
            <span>
              <b>
                {
                  calculateTime(
                    Number(task.isClient ? client_total_time : pm_total_time),
                  ).days
                }
              </b>{" "}
              {t("days")}
            </span>
            <span>
              <b>
                {
                  calculateTime(
                    Number(task.isClient ? client_total_time : pm_total_time),
                  ).hours
                }
              </b>{" "}
              {t("hours")}
            </span>
            <span>
              <b>
                {
                  calculateTime(
                    Number(task.isClient ? client_total_time : pm_total_time),
                  ).minutes
                }
              </b>{" "}
              {t("minutes")}
            </span>
          </span>

          {time_when_read_client && (
            <div className="flex items-center gap-1">
              <span className="text-[14px] text-lightGrayText">
                {time_when_read_client || "12.02.2024 - 15:30"}
              </span>
              <img
                className="min-w-[16px]"
                src={ViewedSvg}
                alt="checked, viewed svg"
              />
            </div>
          )}
        </div>
      </div>

      <DrawerDialog
        open={drawerIsOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            dispatch(setTasksDrawerContent("view"));
          }
          setDrawerIsOpen(false);
        }}
      >
        <DrawerDialogContent>
          <DrawerDialogHeader>
            <DialogTitle>{t("task")}</DialogTitle>
          </DrawerDialogHeader>
          <div
            className="overflow-y-auto px-[24px] pb-[24px]"
            style={{ height: "calc(100vh - 80px)" }}
          >
            {tasksDrawerContent === "view" ? (
              <TaskDetails isClient={isClient} />
            ) : (
              <UpdateTaskForm
                initialState={task}
                onCompleted={() => {
                  setDrawerIsOpen(false);
                }}
              />
            )}
          </div>
        </DrawerDialogContent>
      </DrawerDialog>
    </>
  );
};

export default TaskItem;
