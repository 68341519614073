import { DateRangePicker } from "@/components/common/date-range-picker/date-range-picker";
import { Button } from "@/components/ui/button";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import CustomSelect from "@/components/common/custom-select/custom-select";
import FilterBar from "@/components/common/filter-bar/filter-bar";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { projectStatusesArray } from "../../lib/data/project.statuses";
import {
  setProjectsFromTo,
  setProjectsStatusFilter,
} from "../../store/projects.slice";

interface Props {
  isClient?: boolean;
}

const ProjectsFilter: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const { projectsStatusFilter, projectsFromTo } = useAppSelector(
    (state) => state.projects,
  );
  const dispatch = useAppDispatch();

  return (
    <Popover className="relative">
      {({}) => (
        <>
          <PopoverButton>
            <FilterBar />
          </PopoverButton>
          <PopoverPanel className="absolute right-0 z-10 mt-2 w-80 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t("status")}
                </label>
                <div className="relative">
                  <CustomSelect
                    primaryColor=""
                    onChange={(e) => {
                      dispatch(setProjectsStatusFilter(e));
                    }}
                    value={projectsStatusFilter}
                    options={[
                      { value: "", label: t("all") },
                      ...projectStatusesArray.map((item) => ({
                        value: item.value,
                        label: t(item.label),
                      })),
                    ]}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t("date")}
                </label>
                <div className="relative">
                  <DateRangePicker
                    value={[projectsFromTo.from, projectsFromTo.to]}
                    setValue={(data) => {
                      dispatch(
                        setProjectsFromTo({
                          from: moment(data[0]!).format("YYYY-MM-DD"),
                          to: moment(data[1]!).format("YYYY-MM-DD"),
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div className="my-2 mb-4 w-full border-t border-t-border" />
              <div className="grid grid-cols-2">
                <Button
                  className="mr-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                  onClick={() => {
                    dispatch(setProjectsStatusFilter(""));
                    dispatch(
                      setProjectsFromTo({
                        from: "",
                        to: "",
                      }),
                    );
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button className="rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700">
                  {t("save")}
                </Button>
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default ProjectsFilter;
