// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type IProjectTableData = {
  id: number;
  title: string;
  client: string;
  deadline: any;
  project_stage: any;
  project_status: any;
};

export type ITableColumn = {
  accessorKey: string;
  header: string;
};

export const columns: ITableColumn[] = [
  {
    accessorKey: "title",
    header: "projects",
  },
  {
    accessorKey: "project_manager",
    header: "manager",
  },

  {
    accessorKey: "client",
    header: "client",
  },
  {
    accessorKey: "deadline",
    header: "project_deadline",
  },
];

export const detailedProject: ITableColumn[] = [
  {
    accessorKey: "title",
    header: "projects",
  },
  {
    accessorKey: "project_type",
    header: "type",
  },
  {
    accessorKey: "project_manager",
    header: "manager",
  },

  {
    accessorKey: "client",
    header: "client",
  },

  {
    accessorKey: "project_stage",
    header: "project_stage",
  },
  {
    accessorKey: "project_status",
    header: "status",
  },
  {
    accessorKey: "deadline",
    header: "project_deadline",
  },
];
