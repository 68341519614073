import { TourProvider } from "@reactour/tour";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "./components/common/loading-overlay/loading-overlay";
import { Toaster } from "./components/ui/toaster";
import { steps } from "./lib/tour-data";
import { AppRoutes } from "./routes";
const App = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  return (
    <>
      <TourProvider
        steps={steps.map((item) => ({ ...item, content: t(item.content) }))}
        scrollSmooth
      >
        <LoadingOverlay loading={loading} />
        <Toaster />
        <AppRoutes />
      </TourProvider>
    </>
  );
};

export default App;
