import { z } from "zod";

export const createProjectSchema = z.object({
  title: z.string(),
  project_type_ids: z.array(z.string()),
  start_date: z.string(),
  end_date: z.string(),
  description: z.string(),
  project_manager: z.string().optional(),
  client: z.string(),
  file_paths: z.array(z.string()).optional(),
});

export const updateProjectSchema = z.object({
  title: z.string().optional(),
  project_type_ids: z.array(z.string()).optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  description: z.string().optional(),
  project_manager: z.string().optional(),
  client: z.string().optional(),
  project_status: z.string().optional(),
  project_stage: z.string().optional(),
  pause_reason: z.string().optional(),
  file_paths: z.array(z.string()).optional(),
});
