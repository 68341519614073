import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from "@/features/auth";
import { useAppSelector } from "@/hooks/store-hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { createUserSchema } from "../../lib/validators/user.validator";
import { IUser } from "../../types/users.types";
import LoginPasswordField from "../login-password-field/login-password-field";

interface Props {
  initialState?: IUser;
  onCompleted?: (data: z.infer<typeof createUserSchema>) => void;
  errors?: any[];
}

export function ManageUserForm({ initialState, onCompleted, errors }: Props) {
  const { isCreatingUser } = useAppSelector((state) => state.users);
  const { role } = useAuth();
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof createUserSchema>>({
    resolver: zodResolver(createUserSchema),
    defaultValues: {},
  });

  function onSubmit(values: z.infer<typeof createUserSchema>) {
    onCompleted?.(values);
  }

  function setError() {
    if (errors?.length) {
      errors.map((item: any) => {
        form.setError(item, {
          message: "The username already exist!",
        });
      });
    }
  }

  useEffect(() => {
    setError();
  }, [errors]);

  useEffect(() => {
    if (initialState) {
      Object.keys(initialState).map((item: any) => {
        // @ts-ignore
        form.setValue(item, initialState[item]);
      });
    }
  }, [initialState]);

  console.log(form.formState.errors);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="full_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("name")}*</FormLabel>
              <FormControl>
                <Input placeholder={t("name")} {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("phone")}*</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder={t("phone")}
                  defaultValue={"+998"}
                  onChange={(e) => {
                    field.onChange(e.target.value?.split(" ").join(""));
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("choose_role")}*</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("choose_role")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {role === "PM" ? (
                    <SelectItem value="client">{t("client")}</SelectItem>
                  ) : (
                    <>
                      <SelectItem value="client">{t("client")}</SelectItem>
                      <SelectItem value="admin">{t("admin")}</SelectItem>
                      <SelectItem value="PM">{t("project_manager")}</SelectItem>
                    </>
                  )}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <LoginPasswordField form={form} />
        <Button isLoading={isCreatingUser} className="w-full" type="submit">
          {t("submit")}
        </Button>
      </form>
    </Form>
  );
}
