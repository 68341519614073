import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch } from "@/hooks/store-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { authSchema } from "../../lib/validators/auth.validator";
import { loginAction } from "../../store/auth.action";
import { ILoginReqType } from "../../types/auth.types";
import AuthFormHeader from "../auth-form-header/auth-form-header";
import styles from "./auth-form.module.scss";

const AuthForm = () => {
  const { t } = useTranslation();
  const { isAuthLoading } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(authSchema),
  });
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const onSubmit = (data: ILoginReqType) => {
    dispatch(loginAction(data)).then((res) => {
      if (res.type === "auth/login/rejected") {
        toast({
          title: t("login_or_pass_error"),
          className: "bg-red-500 text-white",
        });
      }
    });
  };

  return (
    <div className={styles.authFormWrapper}>
      <div className={styles.authFormContent}>
        <AuthFormHeader />
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm">
            <div className="mb-5">
              <input
                id="username"
                {...register("username")}
                type="username"
                className={`relative block w-full border bg-white px-[14px] py-[10px] ${
                  errors.username ? "border-red-300" : "border-gray-300"
                } appearance-none rounded-md focus:outline-none sm:text-[16px]`}
                placeholder={t("enter_login")}
              />
              {errors.username && (
                <p className="mt-1 text-sm text-red-500">
                  {errors.username.message}
                </p>
              )}
            </div>
            <div className="mb-5">
              <input
                id="password"
                {...register("password")}
                type="password"
                className={`relative block w-full border bg-white px-[14px] py-[10px] ${
                  errors.password ? "border-red-300" : "border-gray-300"
                } appearance-none rounded-md focus:outline-none sm:text-[16px]`}
                placeholder={t("enter_password")}
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-500">
                  {errors.password.message}
                </p>
              )}
            </div>
          </div>
          <div>
            <Button isLoading={isAuthLoading} className="w-full">
              {t("login")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;
