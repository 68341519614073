import PhotoViewer from "@/components/common/photo-viewer";
import RichTextEditor from "@/components/common/rich-text-editor/rich-text-editor";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { getFileFormat } from "@/lib/get-file-format";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFile } from "react-icons/ai";
import useProjectsTableData from "../../hooks/useProjectsTableData";
import { updateProjectAction } from "../../store/projects.actions";
import {
  setProjectsDrawerContent,
  setUpdatingProjectField,
} from "../../store/projects.slice";
import { detailedProject, IProjectTableData } from "../columns";

interface Props {
  id: number | string;
}

const ProjectDetails: FC<Props> = ({}) => {
  const {
    projects,
    drawerContent,
    updatingProjectId: id,
    isUpdatingProject,
  } = useAppSelector((state) => state.projects);
  const data = useProjectsTableData(projects, true);
  const dispatch = useAppDispatch();
  const [project, setProject] = useState<IProjectTableData>();
  const [pauseReason, setPauseReason] = useState("");
  const { toast } = useToast();
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const { role } = useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    setProject(data.find((item) => item.id === id) as any);
  }, [id, projects]);

  const openUpdateContent = () => {
    dispatch(setProjectsDrawerContent("update"));
  };

  // change project status
  const changeProjectStatus = () => {
    dispatch(setUpdatingProjectField("project_status"));
    dispatch(
      updateProjectAction({
        data: { project_status: "in_pause" },
        id: id!,
        role,
      }),
    ).then((res) => {
      if (res.type === "projects/update/fulfilled") {
        toast({
          title: t("project_canceled"),
          className: "bg-green-500 text-white",
        });
      }
    });
  };

  return (
    <div>
      <div className="h-full flex-grow">
        {detailedProject.map((item) => (
          <div className="mb-[24px]">
            <h2 className="text-md mb-1 font-light text-gray-500">
              {t(item.header)}
            </h2>
            <span className="text-md font-semibold">
              {/* @ts-ignore */}
              {project?.[item.accessorKey]}
            </span>
          </div>
        ))}

        <div>
          <div className="mt-4 grid grid-cols-4 gap-2">
            {project?.file_paths?.map((img) =>
              getFileFormat(img) === "image" ? (
                <div
                  onClick={() => setSelectedPhoto(img)}
                  className="relative h-[100px] w-full cursor-pointer rounded-md border border-border p-1 transition duration-200 hover:border-primary"
                >
                  <img
                    className={`h-full w-full rounded-md object-cover transition duration-200`}
                    src={img}
                  />
                </div>
              ) : (
                <a
                  href={img}
                  download={img}
                  className="relative flex h-[100px] w-full cursor-pointer flex-col items-center justify-center rounded-md border border-border p-1 text-center transition duration-200 hover:border-primary"
                >
                  <AiOutlineFile className="text-4xl text-primary" />

                  <a
                    className="text-primary hover:underline"
                    href={img}
                    download={img}
                  >
                    {t("download")}
                  </a>
                </a>
              ),
            )}
          </div>
        </div>

        {selectedPhoto && (
          <PhotoViewer
            isOpen
            onClose={() => setSelectedPhoto(null)}
            photoUrl={selectedPhoto!}
          />
        )}

        <div className="mb-[24px]">
          <h2 className="text-md mb-1 font-light text-gray-500">
            {t("comment")}
          </h2>
          <span className="text-md font-semibold">-- --</span>
        </div>

        <div className="w-full border-t border-border" />

        {drawerContent === "pause_reason" && (
          <>
            <div className="my-4 flex flex-col">
              <span className="mb-2">{t("enter_pause_reason")}*</span>
              <RichTextEditor
                setValue={(data) => {
                  setPauseReason(data);
                }}
                value={pauseReason}
              />
            </div>

            <Button
              disabled={!pauseReason}
              onClick={changeProjectStatus}
              isLoading={isUpdatingProject}
              variant={"default"}
              className="mb-4 mt-auto w-full"
            >
              {t("save")}
            </Button>
          </>
        )}

        <Button
          onClick={openUpdateContent}
          variant={"outline"}
          className="mt-auto w-full"
        >
          {t("update")}
        </Button>
      </div>
    </div>
  );
};

export default ProjectDetails;
