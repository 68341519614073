export function getFileFormat(url: string): string | null {
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const imageFormats = ["jpg", "png", "svg", "jpeg", "webp"];

    const fileExtension = pathname.split(".").pop();

    if (fileExtension && pathname.includes(".")) {
      return imageFormats.includes(fileExtension.toLowerCase())
        ? "image"
        : "file";
    } else {
      return null;
    }
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}
