import CustomDrawer from "@/components/common/custom-drawer/custom-drawer";
import { TablePagination } from "@/components/common/pagination/pagination";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useArchiveProjectsTableData from "../../hooks/useArchiveProjectsTableData";
import {
  deleteProjectAction,
  getArchiveProjectsAction,
  restoreProjectAction,
} from "../../store/projects.actions";
import {
  setDeleteProjectId,
  setProjectArchivePage,
} from "../../store/projects.slice";
import { IProject } from "../../types";
import ProjectDetails from "./../project-details/project-details";
import { ITableColumn } from "./columns";

interface DataTableProps {
  columns: ITableColumn[];
  data: IProject[];
}

export function ArchiveProjectsTable({ columns, data }: DataTableProps) {
  const { pageArchive, totalCountArchive } = useAppSelector(
    (state) => state.projects,
  );
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const tableData = useArchiveProjectsTableData(data);
  const dispatch = useAppDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [restoreDialog, setrestoreDialog] = useState(false);
  const [restoreId, setRestoreId] = useState(null);
  const { toast } = useToast();
  const { role } = useAuth();
  const { t } = useTranslation();

  const changePage = (page: number) => {
    dispatch(setProjectArchivePage(page));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // delete project
  const deleteHandler = () => {
    dispatch(setDeleteProjectId(deleteId!));
    dispatch(deleteProjectAction({ id: deleteId!, role }))
      .then((res) => {
        if (res.type === "projects/delete/fulfilled") {
          toast({
            title: t("project_full_deleted"),
            className: "bg-green-500 text-white",
          });
        }
      })
      .finally(() => {
        dispatch(getArchiveProjectsAction({ role }));
      });
  };

  // restore project
  const restoreHandler = () => {
    dispatch(setDeleteProjectId(restoreId!));
    dispatch(restoreProjectAction({ id: restoreId!, role })).then((res) => {
      if (res.type === "projects/restore/fulfilled") {
        toast({
          title: t("project_restored"),
          className: "bg-green-500 text-white",
        });
      }
    });
  };

  return (
    <div
      className="overflow-y-auto rounded-md border bg-white shadow-sm"
      style={{ height: "calc(100vh - 230px)" }}
    >
      <Table>
        <TableHeader className="sticky top-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : t(
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          ) as string,
                        )}
                  </TableHead>
                );
              })}
              <TableHead></TableHead>
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            tableData.map((row: any) => (
              <TableRow key={row.id}>
                {columns?.map((cell) => (
                  <TableCell key={cell?.accessorKey}>
                    {row[cell?.accessorKey]}
                  </TableCell>
                ))}
                <TableCell className="flex justify-end">
                  <Button
                    className="px-3 text-red-500"
                    onClick={() => {
                      setDeleteId(row.id);
                      setDeleteDialog(true);
                    }}
                    variant={"ghost"}
                  >
                    {t("delete")}
                  </Button>
                  <Button
                    className="px-3"
                    variant={"ghost"}
                    onClick={() => {
                      setRestoreId(row.id);
                      setrestoreDialog(true);
                    }}
                  >
                    {t("restore")}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t("no_results")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        current={pageArchive}
        totalCount={totalCountArchive}
        setCurrent={(p) => changePage(p)}
      />

      <CustomDrawer
        open={Boolean(selectedProject)}
        onClose={() => setSelectedProject(null)}
        title={t("project")}
        body={() => <ProjectDetails id={selectedProject?.id!} />}
      />

      <AlertDialog open={deleteDialog} onOpenChange={(e) => setDeleteDialog(e)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("do_you_want_delete_project")}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setDeleteDialog(false)}>
              {t("cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => deleteHandler()}
              className="bg-red-500 hover:bg-red-600"
            >
              {t("yes")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={restoreDialog}
        onOpenChange={(e) => setrestoreDialog(e)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("do_you_want_restore_project")}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setrestoreDialog(false)}>
              {t("cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => restoreHandler()}
              className="bg-green-500 hover:bg-green-600"
            >
              {t("yes")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
