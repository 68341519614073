import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";

type SelectPropsAdd = Omit<any, "onChange" | "value">;

type Props = {
  onChange: (value: string) => void;
  options?: any[];
  value?: any;
} & SelectPropsAdd;

const CustomSelect: FC<Props> = ({
  options,
  onChange,
  value,
  isSearchable = true,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState<any>();
  const [localOptions, setLocalOptions] = useState<any[]>([]);
  const { t } = useTranslation();

  const handleChange = (_value: any) => {
    setLocalValue(_value);
    onChange(_value?.value);
  };

  useEffect(() => {
    setLocalValue(options?.find((item) => value === item.value));
  }, [value]);

  useEffect(() => {
    setLocalOptions(options!);
  }, [options]);

  return (
    <AsyncSelect
      isSearchable={isSearchable}
      value={localValue!}
      defaultValue={localValue}
      onChange={handleChange}
      defaultOptions={
        localOptions.map((item) => ({ ...item, label: t(item.label) }))!
      }
      styles={{
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "rgba(3, 152, 85, 1)" : "white",
          ":hover": {
            background: "rgba(3, 152, 85, .2)",
          },
        }),
      }}
      {...rest}
    />
  );
};

export default CustomSelect;
