import { z } from "zod";

export const createUserSchema = z.object({
  full_name: z.string(),
  phone: z.string().min(13).max(15),
  username: z.string(),
  password: z.string(),
  role: z.string(),
});

export const updateUserSchema = z.object({
  full_name: z.string().optional(),
  phone: z.string().max(15).optional(),
  username: z.string().optional(),
  password: z.string().optional(),
  role: z.string().optional(),
});
