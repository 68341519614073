import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { EditSvg } from "@/assets";
import { TablePagination } from "@/components/common/pagination/pagination";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUsersAction, updateeUserAction } from "../store/users.actions";
import { setUserPage } from "../store/users.slice";
import { IUpdateUserType, IUser } from "../types/users.types";
import { UpdateUserForm } from "./manage-user-form/update-user-form";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const { search, page, totalCount } = useAppSelector((state) => state.users);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [errors, setErrors] = useState<any[]>([]);
  const { t } = useTranslation();

  const changePage = (page: number) => {
    dispatch(setUserPage(page));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onUpdateUser = (row: any) => {
    setSelectedUser(row.original);
  };

  const handleUpdateComplete = (data: IUpdateUserType) => {
    dispatch(updateeUserAction({ data, id: selectedUser?.id! })).then(
      (res: any) => {
        if (res.type === "users/update/fulfilled") {
          toast({
            title: t("user_updated"),
            className: "bg-green-500 text-white",
          });
          setSelectedUser(null);
          dispatch(getUsersAction({ search: search! }));
        } else {
          toast({
            title: t("user_already"),
            variant: "destructive",
          });
          setErrors(Object.keys(res.payload.response?.data));
        }
      },
    );
  };

  return (
    <div
      className="z-0 overflow-y-auto rounded-md border bg-white shadow-sm"
      style={{ height: "calc(100vh - 160px)" }}
    >
      <Table>
        <TableHeader className="top-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : t(
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          ) as string,
                        )}
                  </TableHead>
                );
              })}
              <TableHead></TableHead>
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
                <TableCell className="flex justify-end">
                  <Button onClick={() => onUpdateUser(row)} variant={"ghost"}>
                    <img src={EditSvg} />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t("no_results")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        current={page}
        totalCount={totalCount}
        setCurrent={(p) => changePage(p)}
      />

      <Dialog
        open={Boolean(selectedUser)}
        onOpenChange={() => setSelectedUser(null)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("update_user")}</DialogTitle>
          </DialogHeader>
          <div className="px-[24px] pb-[24px]">
            <UpdateUserForm
              initialState={selectedUser!}
              onCompleted={handleUpdateComplete}
              errors={errors}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
