import CustomSelect from "@/components/common/custom-select/custom-select";
import ImageUploader from "@/components/common/image-uploader/image-uploader";
import RichTextEditor from "@/components/common/rich-text-editor/rich-text-editor";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { updateTaskSchema } from "../../lib/validators/tasks.validator";
import { updateTaskAction } from "../../store/tasks.action";
import { ITask, taskStatuses } from "../../types";

interface Props {
  initialState?: ITask;
  onCompleted?: () => void;
  errors?: any[];
}

export function UpdateTaskForm({ initialState, onCompleted, errors }: Props) {
  const { isTaskCreating } = useAppSelector((state) => state.tasks);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof updateTaskSchema>>({
    resolver: zodResolver(updateTaskSchema),
    defaultValues: {},
  });

  function onSubmit(values: any) {
    dispatch(
      updateTaskAction({
        data: values,
        task_id: initialState?.id!,
      }),
    ).then((res) => {
      if (res.type === "tasks/update/fulfilled") {
        toast({
          title: t("task_updated"),
          className: "bg-green-500 text-white",
        });
        onCompleted?.();
      }
    });
  }

  function setError() {
    if (errors?.length) {
      errors.map((item: any) => {
        form.setError(item, {
          message: "Error",
        });
      });
    }
  }

  useEffect(() => {
    setError();
  }, [errors]);

  useEffect(() => {
    if (initialState) {
      Object.keys(initialState).map((item: any) => {
        // @ts-ignore
        form.setValue(item, initialState[item]);
      });
    }
    form.setValue("file_paths", initialState?.file_paths!);
  }, [initialState]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("name_task")}*</FormLabel>
              <FormControl>
                <Input placeholder={t("name_task")} {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("task_description")}*</FormLabel>
              <FormControl>
                <RichTextEditor
                  setValue={(data) => form.setValue("description", data)}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="task_status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Статус задачи</FormLabel>
              <FormControl>
                <CustomSelect
                  isSearchable={false}
                  onChange={(data) => {
                    form.setValue("task_status", data);
                  }}
                  value={field.value}
                  options={taskStatuses.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  primaryColor="primary"
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="file_paths"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("file_upload")}</FormLabel>
              <FormControl>
                <ImageUploader
                  images={field.value}
                  onChange={(images) => form.setValue("file_paths", images)}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button isLoading={isTaskCreating} className="w-full" type="submit">
          {t("submit")}
        </Button>
      </form>
    </Form>
  );
}
