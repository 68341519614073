import { LangSvg, LogoutSvg } from "@/assets";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/features/auth";
import { logout } from "@/features/auth/store/auth.slice";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./sidebar-profile.module.scss";

const SidebarProfile = () => {
  const { sidebarIsOpen } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const [logoutDialog, setLogoutDialog] = useState(false);
  const { full_name, role } = useAuth();
  const { i18n, t } = useTranslation();

  const AvatarComponent = () => (
    <Avatar className="h-[40px] w-[40px] cursor-pointer">
      <AvatarImage width={40} height={40} src={""} />
      <AvatarFallback>{full_name?.[0] || "Б"}</AvatarFallback>
    </Avatar>
  );

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div>
      <Button
        variant={"ghost"}
        className={`mb-4 flex w-full ${sidebarIsOpen ? "justify-start" : "justify-center"} gap-3`}
        onClick={() =>
          i18n.changeLanguage(i18n.language === "ru" ? "uz" : "ru")
        }
      >
        <img src={LangSvg} />
        {sidebarIsOpen && (
          <span className="text-[16px]">
            {i18n.language === "uz" ? "Руский язык" : "O'zbek tili"}
          </span>
        )}
      </Button>
      <div className={styles.sidebarProfile}>
        <div
          className={classNames(
            `h-[50px]cursor-pointer flex items-center gap-4`,
            !sidebarIsOpen && "mx-auto",
          )}
        >
          <AvatarComponent />
          {sidebarIsOpen && (
            <div className="flex min-w-[100px] flex-col">
              <h2 className="font-semibold">{full_name || "Без имени"}</h2>
              <p className="text-grayText">{role}</p>
            </div>
          )}
        </div>

        {sidebarIsOpen && (
          <Button
            onClick={() => setLogoutDialog(true)}
            size={"icon"}
            variant={"ghost"}
          >
            <img src={LogoutSvg} alt="logout svg" />
          </Button>
        )}

        <AlertDialog
          open={logoutDialog}
          onOpenChange={(e) => setLogoutDialog(e)}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t("do_you_want_logout")}</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t("cancel")}</AlertDialogCancel>
              <AlertDialogAction
                onClick={logoutHandler}
                className="bg-red-500 hover:bg-red-600"
              >
                {t("logout")}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
};

export default SidebarProfile;
