import { ProjectsSvg, UsersSvg } from "@/assets";
import { IMenuItemProps } from "@/components/common/sidebar-menu/menu-item/menu-item";

export const sidebarData: IMenuItemProps[] = [
  {
    path: "/projects",
    icon: ProjectsSvg,
    title: "projects",
    subitems: [
      {
        title: "projects",
        path: "/projects",
        icon: ProjectsSvg,
        roles: ["admin", "PM"],
      },
    ],
    roles: ["admin", "PM"],
  },
  {
    path: "/projects-client",
    icon: ProjectsSvg,
    title: "projects",
    roles: ["client"],
  },
  {
    path: "/users",
    icon: UsersSvg,
    title: "users",
    roles: ["admin", "PM"],
  },
];
