import { ColumnDef } from "@tanstack/react-table";
import { IUser } from "../types/users.types";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type IUserTableColumn = {
  id: string | number;
  fullName: string;
  role: string;
};

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "full_name",
    header: "users",
  },
  {
    accessorKey: "phone_number",
    header: "phone",
  },
  {
    accessorKey: "role",
    header: "role",
  },
  {
    accessorKey: "action",
    header: "",
  },
];
