import AddButton from "@/components/common/add-button/add-button";
import PageTitle from "@/components/common/page-title/page-title";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/features/auth";
import { IProject } from "@/features/projects/types";
import { useTour } from "@reactour/tour";
import { LucideMessageCircleQuestion } from "lucide-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateTaskForm } from "./manage-tasks/create-task-form";

interface Props {
  project: IProject;
}

const TasksPageHeader: FC<Props> = ({ project }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { role } = useAuth();
  const { t } = useTranslation();
  const { setIsOpen } = useTour();

  const creatingCompleted = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="flex flex-wrap justify-between gap-4 xs:flex-col xs:items-start lg:flex-row lg:items-center">
      <PageTitle title={project?.title} />
      <div className="flex items-stretch gap-3 xs:w-full xs:flex-col lg:w-auto lg:flex-row">
        <Button
          onClick={() => setIsOpen(true)}
          variant={"outline"}
          size={"icon"}
          className="text-2xl"
        >
          <LucideMessageCircleQuestion className="text-xl" />
        </Button>
        {role !== "client" ? (
          <AddButton
            text={t("add_task")}
            title={t("add_task")}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            formBody={() => <CreateTaskForm onCompleted={creatingCompleted} />}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TasksPageHeader;
