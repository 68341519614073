import AddButton from "@/components/common/add-button/add-button";
import PageTitle from "@/components/common/page-title/page-title";
import SearchBar from "@/components/common/search-bar/search-bar";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useDebounce } from "@/hooks/use-debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUserAction, getUsersAction } from "../store/users.actions";
import { setUserSearch } from "../store/users.slice";
import { ICreateUserType } from "../types/users.types";
import { ManageUserForm } from "./manage-user-form/create-user-form";

const UsersPageHeader = () => {
  const { search } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [errors, setErrors] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const searchValue = useDebounce(searchStr, 500);
  const { page } = useAppSelector((state) => state.users);
  const { role } = useAuth();
  const { t } = useTranslation();

  const createUser = (data: ICreateUserType) => {
    dispatch(
      createUserAction({
        data: {
          ...data,
          phone_number: data.phone.split(" ").join("").slice(0, 14),
        },
        role,
      }),
    ).then((res: any) => {
      if (res.type === "users/create/fulfilled") {
        toast({
          title: t("user_created"),
          className: "bg-green-500 text-white",
        });
        setModalIsOpen(false);
        if (role === "PM") {
          dispatch(getUsersAction({ role, search: search!, page }));
        } else {
          dispatch(getUsersAction({ search: search!, page: page!, role }));
        }
      } else {
        toast({
          title: t("user_already"),
          variant: "destructive",
        });
        setErrors(Object.keys(res.payload.response?.data));
      }
    });
  };

  useEffect(() => {
    dispatch(setUserSearch(searchValue));
    console.log(searchValue);
  }, [searchValue]);

  return (
    <div className="flex justify-between mb-8 xs:flex-col xs:items-start xs:gap-4 md:flex-row lg:items-center">
      <PageTitle title={t("users")} />

      <div className="flex items-stretch gap-3 xs:w-full xs:flex-col md:w-auto lg:flex-row">
        <SearchBar
          onChange={(e) => {
            console.log();
            setSearchStr(e);
          }}
          value={searchStr}
        />
        <AddButton
          formBody={() => (
            <ManageUserForm onCompleted={createUser} errors={errors} />
          )}
          setModalIsOpen={setModalIsOpen}
          text={t("add_user")}
          title={t("add_user")}
          modalIsOpen={modalIsOpen}
        />
      </div>
    </div>
  );
};

export default UsersPageHeader;
