import classNames from "classnames";
import { FC } from "react";
import { Bars } from "react-loader-spinner";

interface Props {
  loading?: boolean;
}

const LoadingOverlay: FC<Props> = ({ loading }) => {
  return (
    <div
      className={classNames(
        "fixed inset-0 z-50 flex h-screen w-full items-center justify-center rounded-md bg-white py-8 data-[state=closed]:hidden data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-in-0 data-[state=closed]:fade-out-0",
      )}
      data-state={loading ? "open" : "closed"}
      style={{ zIndex: 99999999 }}
    >
      <Bars
        height="40"
        width="40"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default LoadingOverlay;
