import { IUser } from "@/features/users/types/users.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProjectTableData } from "../components/columns";
import { IProject, IProjectType } from "../types";
import {
  archiveProjectAction,
  createProjectAction,
  deleteProjectAction,
  getAllProjectsAction,
  getArchiveProjectsAction,
  getClients,
  getProjectManagers,
  getProjectTypes,
  restoreProjectAction,
  updateProjectAction,
} from "./projects.actions";

type UpdatingProjectFieldsType =
  | "project_stage"
  | "project_status"
  | "all"
  | null;

type DrawerTypes = "view" | "update" | "pause_reason";

export type FromToType = {
  from: string;
  to: string;
};

interface IProjectInitialState {
  projects: IProject[];
  archiveProjects: IProject[];
  isProjectsLoading: boolean;
  isCreatingProject: boolean;
  projectTableData: IProjectTableData[];
  projectManagers: IUser[];
  clients: IUser[];
  isUpdatingProject: boolean;
  updatingProjectId: number | string | null;
  updatingProjectField: UpdatingProjectFieldsType;
  projectTypes: IProjectType[];
  deletingProjectId: number | string | null;
  drawerContent: DrawerTypes;
  projectsDrawerIsOpen: boolean;
  page: number;
  totalCount: number;
  projectsSearch: string;
  projectsStatusFilter: string;
  projectsFromTo: FromToType;
  totalCountArchive: number;
  pageArchive: number;
}

const initialState: IProjectInitialState = {
  projects: [],
  isProjectsLoading: false,
  projectTableData: [],
  isCreatingProject: false,
  projectManagers: [],
  clients: [],
  isUpdatingProject: false,
  updatingProjectId: null,
  updatingProjectField: null,
  projectTypes: [],
  deletingProjectId: null,
  archiveProjects: [],
  drawerContent: "view",
  projectsDrawerIsOpen: false,
  page: 1,
  pageArchive: 1,
  totalCount: 10,
  totalCountArchive: 10,
  projectsSearch: "",
  projectsStatusFilter: "",
  projectsFromTo: { from: "", to: "" },
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setUpdatingProjectId: (
      state,
      action: PayloadAction<string | number | null>,
    ) => {
      state.updatingProjectId = action.payload;
    },
    setUpdatingProjectField: (
      state,
      action: PayloadAction<UpdatingProjectFieldsType>,
    ) => {
      state.updatingProjectField = action.payload;
    },
    setDeleteProjectId: (
      state,
      action: PayloadAction<number | string | null>,
    ) => {
      state.deletingProjectId = action.payload;
    },
    setProjectsDrawerContent: (state, action: PayloadAction<DrawerTypes>) => {
      state.drawerContent = action.payload;
    },
    toggleProjectsDrawer: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.projectsDrawerIsOpen =
        action.payload || !state.projectsDrawerIsOpen;
      state.drawerContent = "view";
    },
    setProjectPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setProjectArchivePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setProjectsSearch: (state, action: PayloadAction<string>) => {
      state.projectsSearch = action.payload;
    },
    setProjectsStatusFilter: (state, action: PayloadAction<string>) => {
      state.projectsStatusFilter = action.payload;
    },
    setProjectsFromTo: (state, action: PayloadAction<FromToType>) => {
      state.projectsFromTo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllProjectsAction.pending, (state) => {
        state.isProjectsLoading = true;
      })
      .addCase(getAllProjectsAction.fulfilled, (state, action) => {
        state.projects = action.payload.results;
        state.totalCount = action.payload.count;
        state.isProjectsLoading = false;
      })
      .addCase(getAllProjectsAction.rejected, (state) => {
        state.isProjectsLoading = false;
      })
      .addCase(getArchiveProjectsAction.pending, (state) => {
        state.isProjectsLoading = true;
      })
      .addCase(getArchiveProjectsAction.fulfilled, (state, action) => {
        state.archiveProjects = action.payload.results;
        state.totalCountArchive = action.payload.count;
        state.isProjectsLoading = false;
      })
      .addCase(getArchiveProjectsAction.rejected, (state) => {
        state.isProjectsLoading = false;
      })
      .addCase(createProjectAction.pending, (state) => {
        state.isCreatingProject = true;
      })
      .addCase(
        createProjectAction.fulfilled,
        (state, action: PayloadAction<IProject>) => {
          state.isCreatingProject = false;
          state.projects = [action.payload, ...state.projects];
        },
      )
      .addCase(createProjectAction.rejected, (state) => {
        state.isCreatingProject = false;
      })
      .addCase(getProjectManagers.pending, (state) => {
        state.isCreatingProject = true;
      })
      .addCase(getProjectManagers.fulfilled, (state, action) => {
        state.isCreatingProject = false;
        state.projectManagers = action.payload.results;
      })
      .addCase(getProjectManagers.rejected, (state) => {
        state.isCreatingProject = false;
      })
      .addCase(getClients.pending, (state) => {
        state.isCreatingProject = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.isCreatingProject = false;
        state.clients = action.payload.results;
      })
      .addCase(getClients.rejected, (state) => {
        state.isCreatingProject = false;
      })
      .addCase(updateProjectAction.pending, (state) => {
        state.isUpdatingProject = true;
      })
      .addCase(
        updateProjectAction.fulfilled,
        (state, action: PayloadAction<IProject>) => {
          state.projects = state.projects.map((item) => {
            if (item.id === state.updatingProjectId) {
              return action.payload;
            } else {
              return item;
            }
          });
          state.isUpdatingProject = false;
          state.updatingProjectId = null;
          state.updatingProjectField = null;
          state.drawerContent = "view";
          state.projectsDrawerIsOpen = false;
        },
      )
      .addCase(updateProjectAction.rejected, (state) => {
        state.isUpdatingProject = false;
      })
      .addCase(getProjectTypes.pending, (state) => {
        state.isCreatingProject = true;
      })
      .addCase(getProjectTypes.fulfilled, (state, action) => {
        state.isCreatingProject = false;
        state.projectTypes = action.payload.results;
      })
      .addCase(getProjectTypes.rejected, (state) => {
        state.isCreatingProject = false;
      })
      .addCase(deleteProjectAction.fulfilled, (state) => {
        state.projects = state.projects.filter(
          (item) => item.id !== state.deletingProjectId,
        );
        state.deletingProjectId = null;
      })
      .addCase(deleteProjectAction.rejected, (state) => {
        state.deletingProjectId = null;
      })
      .addCase(archiveProjectAction.fulfilled, (state) => {
        const currentProject = state.projects.find(
          (item) => item.id === state.deletingProjectId,
        );
        state.projects = state.projects.filter(
          (item) => item.id !== state.deletingProjectId,
        );
        state.archiveProjects = [currentProject!, ...state.archiveProjects];
        state.deletingProjectId = null;
      })
      .addCase(archiveProjectAction.rejected, (state) => {
        state.deletingProjectId = null;
      })
      .addCase(restoreProjectAction.fulfilled, (state) => {
        const currentProject = state.archiveProjects.find(
          (item) => item.id === state.deletingProjectId,
        );
        state.archiveProjects = state.archiveProjects.filter(
          (item) => item.id !== state.deletingProjectId,
        );
        state.projects = [currentProject!, ...state.projects];
        state.deletingProjectId = null;
      })
      .addCase(restoreProjectAction.rejected, (state) => {
        state.deletingProjectId = null;
      });
  },
});

export const {
  setUpdatingProjectId,
  setUpdatingProjectField,
  setDeleteProjectId,
  setProjectsDrawerContent,
  toggleProjectsDrawer,
  setProjectPage,
  setProjectsSearch,
  setProjectsStatusFilter,
  setProjectsFromTo,
  setProjectArchivePage,
} = projectsSlice.actions;
export default projectsSlice.reducer;
