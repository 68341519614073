import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuth } from "@/features/auth";
import { IProject } from "@/features/projects/types";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getAdminArchiveTasks,
  getAdminTasks,
  getClientArchiveTasks,
  getClientTasks,
  getConversionData,
} from "../store/tasks.action";
import TasksColumn from "./tasks-column/tasks-column";
import TasksPageHeader from "./tasks-page-header";
import TasksStatistics from "./tasks-statistics/tasks-statistics";

const TasksPageComponent = () => {
  const { projects } = useAppSelector((state) => state.projects);
  const {
    adminTasksStatusFilter,
    clientTasksStatusFilter,
    adminTaskDateFilter,
    clientTaskDateFilter,
    adminTaskSearch,
    clientTaskSearch,
    conversionDateFilter,
  } = useAppSelector((state) => state.tasks);
  const { role } = useAuth();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [project, setProject] = useState<IProject | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getClientTasks({
        project_id: params.id!,
        statuses: clientTasksStatusFilter?.length
          ? clientTasksStatusFilter?.reduce((acc, next) => acc + `${next}|`, "")
          : "send_for_confirmation|confirmed",
        date: clientTaskDateFilter,
        search: clientTaskSearch,
      }),
    );

    dispatch(
      getClientArchiveTasks({
        project_id: params.id!,
        statuses: clientTasksStatusFilter?.length
          ? clientTasksStatusFilter?.reduce((acc, next) => acc + `${next}|`, "")
          : "send_for_confirmation|confirmed",
      }),
    );
    setProject(projects?.find((item) => String(item.id) === params.id!)!);
  }, [
    params.id,
    clientTasksStatusFilter,
    clientTaskDateFilter,
    clientTaskSearch,
  ]);

  useEffect(() => {
    dispatch(
      getAdminTasks({
        project_id: params.id!,
        statuses: adminTasksStatusFilter?.length
          ? adminTasksStatusFilter?.reduce((acc, next) => acc + `${next}|`, "")
          : "new|rejected|in_developing",
        date: adminTaskDateFilter,
        search: adminTaskSearch,
      }),
    );

    dispatch(
      getAdminArchiveTasks({
        project_id: params.id!,
        statuses: adminTasksStatusFilter?.length
          ? adminTasksStatusFilter?.reduce((acc, next) => acc + `${next}|`, "")
          : "new|rejected|in_developing",
      }),
    );
  }, [params.id, adminTasksStatusFilter, adminTaskDateFilter, adminTaskSearch]);

  useEffect(() => {
    dispatch(
      getConversionData({
        projectId: params.id!,
        fromTo: conversionDateFilter,
      }),
    );
  }, [params.id, conversionDateFilter]);

  return (
    <div className="container py-10 mx-auto">
      <TasksPageHeader project={project!} />

      <Tabs defaultValue="account" className="w-full">
        <TabsList className="first-step mb-[32px] mt-[20px]">
          <TabsTrigger value="account">{t("tasks_list")}</TabsTrigger>
          {role !== "client" ? (
            <TabsTrigger value="password">{t("archive")}</TabsTrigger>
          ) : null}
        </TabsList>
        <TasksStatistics project={project!} />
        <TabsContent value="account">
          <div className="mt-[31px] grid gap-4 xs:grid-cols-1 md:grid-cols-2">
            <TasksColumn />
            <TasksColumn isClient />
          </div>
        </TabsContent>
        <TabsContent value="password">
          <div className="mt-[31px] grid gap-4 xs:grid-cols-1 md:grid-cols-2">
            <TasksColumn isArchive />
            <TasksColumn isArchive isClient />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default TasksPageComponent;
