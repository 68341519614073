import { useAppSelector } from "@/hooks/store-hooks";
import { sidebarData } from "@/lib/data/sidebar-data";
import MenuItem from "./menu-item/menu-item";
import styles from "./sidebar-menu.module.scss";

const SidebarMenu = () => {
  const { sidebarIsOpen } = useAppSelector((state) => state.ui);
  return (
    <div className={sidebarIsOpen ? styles.open : styles.collapse}>
      {sidebarData.map((item) => (
        <MenuItem {...item} key={item.path} />
      ))}
    </div>
  );
};

export default SidebarMenu;
