import { FromToType } from "./../features/projects/store/projects.slice";
// auth urls
export const login = () => "/authapp/access/";
export const refresh = () => "/authapp/refresh/";

// users urls
export const getUsersUrl = (
  search: string,
  page: number,
  role: string = "admin",
) =>
  `api/v1/${role?.toLowerCase()}/${role === "PM" ? "clients" : "users"}/?search=${search}&page=${page}`;
export const getPmsUrl = (search: string, page: number) =>
  `api/v1/admin/users/?search=${search}&page=${page}&role=PM`;

export const getClientsUrl = (
  search: string,
  page: number,
  role: string = "admin",
) =>
  `api/v1/${role?.toLowerCase()}/${role === "PM" ? "clients" : "users"}/?search=${search}&page=${page}&role=client`;

export const createUserUrl = (role: string = "admin") =>
  `/api/v1/${role}/${role === "pm" ? "clients" : "users"}/`;
export const updateUserUrl = (id: number) => `/api/v1/admin/users/${id}/`;

// projects urls
export const getProjectsUrl = (
  role: string,
  page: number | string,
  search: string = "",
  fromTo: FromToType,
  status: string,
) =>
  `/api/v1/${role}/projects/?page=${page || 1}&search=${search}&from_date=${fromTo.from}&to_date=${fromTo.to}&project_status=${status}`;
export const getArchiveProjectsUrl = (
  role: string = "admin",
  page: number = 1,
) => `/api/v1/${role}/projects/archive/?page=${page}`;
export const createProjectUrl = (role: string = "admin") =>
  `/api/v1/${role.toLowerCase()}/projects/`;
export const updateProjectUrl = (id: string | number, role: string = "admin") =>
  `/api/v1/${role.toLowerCase()}/projects/${id}/`;
export const getProjectTypesUrl = () => `/api/v1/admin/project-types/`;
export const deleteProjectUrl = (id: string | number, role: string = "admin") =>
  `/api/v1/${role.toLowerCase()}/projects/${id}/`;

// tasks urls
export const getAdminTasksUrl = (
  project_id: string | number,
  statuses: string = "",
  date: FromToType = { from: "", to: "" },
  search: string = "",
) =>
  `/api/v1/admin/${project_id}/tasks/?status=${statuses}&from_date=${date.from}&to_date=${date.to}&search=${search}`;
export const getPMTasksUrl = () => `
api/v1/administration/pm/project/task/list/`;

export const getClientTasksUrl = (
  project_id: string | number,
  statuses: string = "",
  date: FromToType = { from: "", to: "" },
  search: string = "",
) => `
api/v1/admin/${project_id}/tasks/?status=${statuses}&from_date=${date.from}&to_date=${date.to}&search=${search}`;

export const createTaskUrl = (project_id: string | number) => `
api/v1/admin/${project_id}/tasks/`;
export const updateTaskUrl = (id: string | number) =>
  `/api/v1/admin/tasks/${id}/`;

export const getArchiveTasks = (
  project_id: string | number,
  statuses: string = "",
) => `/api/v1/admin/${project_id}/tasks/archive/?status=${statuses}`;

export const getConversionDataUrl = (
  projectId: number | string,
  fromTo: FromToType,
) =>
  `/api/v1/conversion/${projectId}/?from_date=${fromTo.from}&to_date=${fromTo.to}`;

// file upload
export const fileUploadUrl = () => `api/v1/admin/project-files/`;
