import classNames from "classnames";
import { FC } from "react";
import { Bars } from "react-loader-spinner";

interface Props {
  className?: string;
  height?: string;
}

const TableSkeleton: FC<Props> = ({ className, height }) => {
  return (
    <div
      className={classNames(
        "flex w-full items-center justify-center rounded-md bg-white py-8",
        className,
      )}
      style={{ height: height || "calc(100vh - 160px)" }}
    >
      <Bars
        height="40"
        width="40"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default TableSkeleton;
