import { IStatusTypes } from "@/components/common/selectable-status/selectable-status";

export const projectStageStatus: any = {
  technical_task: "technical_task",
  design: "design",
  in_developing: "in_development",
  testing: "testing",
  completed: "completed",
};

export const projectStageStatusArray = [
  {
    label: "technical_task",
    value: "technical_task",
  },
  {
    label: "design",
    value: "design",
  },
  {
    label: "in_developing",
    value: "in_developing",
  },
  {
    label: "testing",
    value: "testing",
  },
  {
    label: "completed",
    value: "completed",
  },
];

type IProjectStatusFieldType = {
  text: string;
  status: IStatusTypes;
};

export interface IProjectStatusType {
  new: IProjectStatusFieldType;
  in_progress: IProjectStatusFieldType;
  in_pause: IProjectStatusFieldType;
  completed: IProjectStatusFieldType;
}

export const projectStatuses: IProjectStatusType = {
  new: {
    text: "new",
    status: "default",
  },
  in_progress: {
    text: "process",
    status: "pending",
  },
  in_pause: {
    text: "in_pause",
    status: "rejected",
  },
  completed: {
    text: "completed",
    status: "success",
  },
};

export const projectStatusesArray = [
  {
    label: "new",
    value: "new",
    status: "default",
  },
  {
    label: "process",
    value: "in_progress",
    status: "pending",
  },
  {
    label: "in_pause",
    value: "in_pause",
    status: "rejected",
  },
  {
    label: "completed",
    value: "completed",
    status: "success",
  },
];
