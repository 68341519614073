import TableSkeleton from "@/components/common/table-skeleton/table-skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getAllProjectsAction,
  getArchiveProjectsAction,
} from "../store/projects.actions";
import { columns as archiveColumns } from "./archive-projects/columns";
import { ArchiveProjectsTable } from "./archive-projects/data-table";
import { columns } from "./columns";
import { ProjectsTable } from "./data-table";
import ProjectsPageHeader from "./projects-page-header";

export default function ProjectsPageComponent() {
  const {
    projects,
    isProjectsLoading,
    archiveProjects,
    page,
    projectsSearch,
    projectsFromTo,
    projectsStatusFilter,
    pageArchive,
  } = useAppSelector((state) => state.projects);
  const { role } = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getAllProjectsAction({
        role: role.toLowerCase(),
        page,
        search: projectsSearch,
        fromTo: projectsFromTo,
        status: projectsStatusFilter,
      }),
    );
    dispatch(
      getArchiveProjectsAction({ role: role.toLowerCase(), page: pageArchive }),
    );
  }, [page, projectsSearch, projectsFromTo, projectsStatusFilter, pageArchive]);

  return (
    <div className="container mx-auto py-10">
      <ProjectsPageHeader />
      <Tabs defaultValue="account" className="w-full">
        <TabsList className="mb-[32px] mt-[20px]">
          <TabsTrigger value="account">{t("projects_list")}</TabsTrigger>
          <TabsTrigger value="password">{t("archive")}</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          {isProjectsLoading ? (
            <TableSkeleton />
          ) : (
            <ProjectsTable columns={columns} data={projects} />
          )}
        </TabsContent>
        <TabsContent value="password">
          {isProjectsLoading ? (
            <TableSkeleton />
          ) : (
            <ArchiveProjectsTable
              columns={archiveColumns}
              data={archiveProjects}
            />
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}
