import { SearchSvg, XSvg } from "@/assets";
import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
}

const SearchBar: FC<IProps> = ({ value, onChange, className }) => {
  const { t } = useTranslation();
  return (
    <label
      className={classNames(
        "flex items-center justify-between rounded-[8px] border border-border bg-white px-[16px] py-[10px] xs:w-full md:w-[320px]",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <img src={SearchSvg} alt={`Search svg`} />

        <input
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          className="w-full bg-transparent outline-none"
          type="text"
          placeholder={t("search")}
        />
      </div>

      {value && (
        <i className="cursor-pointer" onClick={() => onChange?.("")}>
          <img src={XSvg} />
        </i>
      )}
    </label>
  );
};

export default SearchBar;
