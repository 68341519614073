import TableSkeleton from "@/components/common/table-skeleton/table-skeleton";
import { IProject } from "@/features/projects/types";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setConversionFromTo } from "../../store/tasks.slice";
import TaskStatisticsItem from "../task-statistics-item/task-statistics-item";
import styles from "./tasks-statistics.module.scss";

const TasksStatistics = ({ project }: { project: IProject }) => {
  const { conversion, isConversionLoading } = useAppSelector(
    (state) => state.tasks,
  );
  const [_, setHours] = useState({
    musait: conversion?.pm_total_time!,
    client: conversion?.client_total_time!,
  });

  const [fromTo] = useState({ from: "", to: "" });

  const {} = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fromTo.from && fromTo.to) {
      setTimeout(() => {
        dispatch(
          setConversionFromTo({
            from: fromTo.from,
            to: fromTo.to,
          }),
        );
      }, 500);
    }
  }, [fromTo]);

  useEffect(() => {
    setHours({
      musait:
        ((conversion?.pm_total_time || 0!) /
          (conversion?.total_project_time || 0!)) *
        100,
      client:
        ((conversion?.client_total_time || 0!) /
          (conversion?.total_project_time || 0!)) *
        100,
    });
  }, [conversion]);

  return (
    <div className={styles.tasksStatistics}>
      {isConversionLoading ? (
        <TableSkeleton height="190px" />
      ) : (
        <>
          <div className="grid gap-4 xs:grid-cols-1 lg:grid-cols-2">
            <TaskStatisticsItem
              project={project}
              client_total_time={conversion?.client_total_time!}
              pm_total_time={conversion?.pm_total_time!}
              position={"company"}
              total_project_time={conversion?.total_project_time!}
            />
            <TaskStatisticsItem
              project={project}
              client_total_time={conversion?.client_total_time!}
              pm_total_time={conversion?.pm_total_time!}
              position={"client"}
              total_project_time={conversion?.total_project_time!}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TasksStatistics;
