import { ChevronBottomSvg } from "@/assets";
import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { toggleSidebar } from "@/store/ui/ui.slice";

const SidebarToggler = () => {
  const { sidebarIsOpen } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  // toggle sidebar
  const toggle = () => dispatch(toggleSidebar(undefined));

  return (
    <Button
      className="absolute -right-[20px] top-[50px] flex h-[40px] w-[40px] items-center rounded-full p-0"
      variant={"outline"}
      onClick={toggle}
    >
      <img
        className={sidebarIsOpen ? "rotate-90" : "-rotate-90"}
        src={ChevronBottomSvg}
        alt="chevron bottom"
      />
    </Button>
  );
};

export default SidebarToggler;
