import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  value?: string;
  setValue?: (data: string) => void;
}

function RichTextEditor({ value, setValue }: Props) {
  return (
    <ReactQuill
      className="rounded-md"
      theme="snow"
      value={value}
      onChange={(e) => setValue?.(e)}
      modules={{
        toolbar: {
          container: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      }}
    />
  );
}

export default RichTextEditor;
