import { IProject } from "@/features/projects/types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "../circular-progress-bar";

export function calculateTime(totalHours: number) {
  const days = Math.floor(totalHours / 24);
  const hours = Math.floor(totalHours % 24);
  const minutes = Math.floor((totalHours * 60) % 60);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
  };
}

interface Props {
  client_total_time: number;
  pm_total_time: number;
  total_project_time: number;
  position: "client" | "company";
  project: IProject;
}

const TaskStatisticsItem: FC<Props> = ({
  client_total_time,
  pm_total_time,
  total_project_time,
  position,
  project,
}) => {
  const time = position === "client" ? client_total_time : pm_total_time;
  const { t } = useTranslation();

  return (
    <div
      className={`${position === "client" ? "three-step" : "two-steo"} rounded-[12px] border border-gray-100 p-[24px] shadow-sm`}
    >
      <div className="mb-[24px] flex w-full items-center justify-between gap-4">
        <h2 className="text-lg font-semibold">{t("project_time")}</h2>

        <span className="font-medium text-grayText">
          {project?.start_date} - {project?.end_date}
        </span>
      </div>

      <div className="flex items-center gap-[24px] xs:flex-col md:flex-row">
        {/* circle */}
        <CircularProgressBar
          position={position}
          spendHours={time}
          totalHours={total_project_time}
        />

        {/* timer */}
        <div className="grid w-full">
          <span className="mb-2 font-medium uppercase text-grayText">
            {position === "client" ? t("client") : "Musait"}
          </span>

          <div className="grid gap-3 xs:grid-cols-1 md:grid-cols-3">
            <div
              className="flex items-end justify-center gap-1 px-2 py-1 rounded-lg shadow-sm bg-grayBg text-grayText"
              style={{ background: "rgba(249, 250, 251, 1)" }}
            >
              <h1 className="font-semibold xs:text-[30px] xl:text-[50px]">
                {calculateTime(time).days}
              </h1>
              <span className="mb-3 xs:text-[15px] xl:text-[25px]">
                {t("days")}
              </span>
            </div>
            <div
              className="flex items-end justify-center gap-1 px-2 py-1 rounded-lg shadow-sm bg-grayBg text-grayText"
              style={{ background: "rgba(249, 250, 251, 1)" }}
            >
              <h1 className="font-semibold xs:text-[30px] xl:text-[50px]">
                {calculateTime(time).hours}
              </h1>
              <span className="mb-3 xs:text-[15px] xl:text-[25px]">
                {t("hours")}
              </span>
            </div>
            <div
              className="flex items-end justify-center gap-1 px-2 py-1 rounded-lg shadow-sm bg-grayBg text-grayText"
              style={{ background: "rgba(249, 250, 251, 1)" }}
            >
              <h1 className="font-semibold xs:text-[30px] xl:text-[50px]">
                {calculateTime(time).minutes}
              </h1>
              <span className="mb-3 xs:text-[15px] xl:text-[25px]">
                {" "}
                {t("minutes")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskStatisticsItem;
