import { FC } from "react";
import BackButton from "../back-button";

interface Props {
  title?: string;
  description?: string;
  isBack?: boolean;
}

const PageTitle: FC<Props> = ({ title, isBack = true }) => {
  return (
    <div className="flex items-center gap-4">
      {isBack ? <BackButton /> : null}
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold">{title}</h1>
      </div>
    </div>
  );
};

export default PageTitle;
