import PhotoViewer from "@/components/common/photo-viewer";
import RichTextEditor from "@/components/common/rich-text-editor/rich-text-editor";
import SelectableStatus, {
  IStatusTypes,
} from "@/components/common/selectable-status/selectable-status";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { getFileFormat } from "@/lib/get-file-format";
import parser from "html-react-parser";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFile } from "react-icons/ai";
import { useParams } from "react-router-dom";
import {
  getAdminArchiveTasks,
  getClientArchiveTasks,
  updateTaskAction,
} from "../../store/tasks.action";
import { setTasksDrawerContent } from "../../store/tasks.slice";
import { ITask, taskStatuses } from "../../types";
import { TastStatusHistories } from "../task-status-histories/task-status-histories";

interface Props {
  isClient?: boolean;
}

const TaskDetails: FC<Props> = ({ isClient }) => {
  const {
    adminTasks,
    selectedTaskId,
    clientTasks,
    adminArchiveTasks,
    isTaskCreating,
  } = useAppSelector((state) => state.tasks);
  const { role } = useAuth();
  const [isCancel, setIsCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [task, setTask] = useState<ITask | null>(null);
  const { toast } = useToast();
  const params = useParams();
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const openTasksUpdate = () => {
    dispatch(setTasksDrawerContent("update"));
  };

  useEffect(() => {
    const fromClient = clientTasks.find((item) => item.id === selectedTaskId!)!;
    const fromAdmin = adminTasks.find((item) => item.id === selectedTaskId!)!;
    const fromAdminArchive = adminArchiveTasks.find(
      (item) => item.id === selectedTaskId!,
    )!;
    setTask(fromClient || fromAdmin || fromAdminArchive);
  }, [selectedTaskId]);

  function onUpdateStatus(status: string) {
    dispatch(
      updateTaskAction({
        data: { task_status: status, reject_reason: cancelReason },
        task_id: selectedTaskId!,
      }),
    ).then((res) => {
      if (res.type === "tasks/update/fulfilled") {
        toast({
          title: "Статус задачи изменился!",
          className: "bg-green-500 text-white",
        });
      }
    });
  }
  function onArchiveProject() {
    dispatch(
      updateTaskAction({
        data: { archive: true },
        task_id: selectedTaskId!,
      }),
    ).then((res) => {
      if (res.type === "tasks/update/fulfilled") {
        dispatch(
          getAdminArchiveTasks({
            project_id: params.id!,
            statuses: "new|in_developing|reject",
          }),
        );
        dispatch(
          getClientArchiveTasks({
            project_id: params.id!,
            statuses: "send_for_confirmation|confirmed",
          }),
        );
        toast({
          title: t("task_status_changed"),
          className: "bg-green-500 text-white",
        });
      }
    });
  }

  return (
    <div className="flex flex-col items-start min-h-full">
      <div className="mb-[24px]">
        <h2 className="mb-1 font-light text-gray-500 text-md">{t("task")}</h2>
        <span className="font-medium text-md">{task?.title}</span>
      </div>
      <div className="mb-[24px] w-full rounded-lg bg-gray-50 p-2">
        <h2 className="mb-1 font-light text-gray-500 text-md">
          {t("description")}
        </h2>
        <span className="font-medium text-md">
          {parser(task?.description || "")}
        </span>
      </div>
      <div>
        <div className="grid grid-cols-4 gap-2 mb-4">
          {task?.file_paths?.map((img) =>
            getFileFormat(img) === "image" ? (
              <div
                onClick={() => setSelectedPhoto(img)}
                className="relative h-[100px] w-full cursor-pointer rounded-md border border-border p-1 transition duration-200 hover:border-primary"
              >
                <img
                  className={`h-full w-full rounded-md object-cover transition duration-200`}
                  src={img}
                />
              </div>
            ) : (
              <a
                href={img}
                download={img}
                className="relative flex h-[100px] w-full cursor-pointer flex-col items-center justify-center rounded-md border border-border p-1 text-center transition duration-200 hover:border-primary"
              >
                <AiOutlineFile className="text-4xl text-primary" />

                <a
                  className="text-primary hover:underline"
                  href={img}
                  download={img}
                >
                  {t("download")}
                </a>
              </a>
            ),
          )}
        </div>
      </div>

      <div className="mb-[24px] w-full border-t border-t-border" />

      <div className="mb-[24px] flex flex-col items-start">
        <h2 className="mb-1 font-light text-gray-500 text-md">
          {t("status_task")}
        </h2>
        <SelectableStatus
          readonly
          status={
            taskStatuses.find((item) => item.value === task?.task_status)
              ?.status as IStatusTypes
          }
          text={
            taskStatuses.find((item) => item.value === task?.task_status)
              ?.label as IStatusTypes
          }
          menuItems={taskStatuses}
        />
      </div>
      {task?.reject_reason && (
        <div className="mb-[24px] flex w-full flex-col items-start">
          <h2 className="mb-1 font-light text-gray-500 text-md">
            {t("reject_reason")}:
          </h2>
          <p className="w-full rounded-md bg-gray-100 px-[14px] py-[10px]">
            {parser(task?.reject_reason || "")}
          </p>
        </div>
      )}

      <div className="mb-[24px]">
        <h2 className="mb-1 font-light text-gray-500 text-md">{t("date")}:</h2>
        <span className="font-medium text-md">
          {moment(task?.created_at).format("DD/MM/YYYY")}
        </span>
      </div>
      <div className="mb-[24px] w-full border-t border-t-border" />

      <TastStatusHistories task={task!} />

      <div className="mb-[24px] w-full border-t border-t-border" />

      {isCancel && (
        <>
          <div className="flex flex-col w-full">
            <span className="mb-2">{t("write_reject_reason")}*</span>
            <RichTextEditor
              setValue={(data) => {
                setCancelReason(data);
              }}
              value={cancelReason}
            />
          </div>

          <Button
            isLoading={isTaskCreating}
            disabled={!cancelReason}
            onClick={() => onUpdateStatus("reject")}
            variant={"default"}
            className="mb-4 mt-[24px] w-full"
          >
            {t("save")}
          </Button>
        </>
      )}

      {!isClient ? (
        role === "client" ? null : task?.archive ? null : (
          <div className="grid w-full grid-cols-2 gap-3 mt-auto">
            <Button
              isLoading={isTaskCreating}
              onClick={() => {
                onArchiveProject();
              }}
              className="text-red-500 bg-red-500/20 hover:bg-red-500/30"
            >
              {t("delete")}
            </Button>
            <Button
              isLoading={isTaskCreating}
              variant={"outline"}
              className="w-full mt-auto"
              onClick={openTasksUpdate}
            >
              {t("update")}
            </Button>
          </div>
        )
      ) : (
        role === "client" &&
        !isCancel && (
          <div className="grid w-full grid-cols-2 gap-3 mt-auto">
            <Button
              isLoading={isTaskCreating}
              onClick={() => onUpdateStatus("confirmed")}
              className="text-green-500 bg-green-500/20 hover:bg-green-500/30"
            >
              {t("confirm")}
            </Button>
            <Button
              isLoading={isTaskCreating}
              onClick={() => {
                setIsCancel(true);
              }}
              className="text-red-500 bg-red-500/20 hover:bg-red-500/30"
            >
              {t("reject")}
            </Button>
          </div>
        )
      )}

      {selectedPhoto && (
        <PhotoViewer
          isOpen
          onClose={() => setSelectedPhoto(null)}
          photoUrl={selectedPhoto!}
        />
      )}
    </div>
  );
};

export default TaskDetails;
