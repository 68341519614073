import TableSkeleton from "@/components/common/table-skeleton/table-skeleton";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useEffect } from "react";
import { getUsersAction } from "../store/users.actions";
import { columns } from "./columns";
import { DataTable } from "./data-table";
import UsersPageHeader from "./users-page-header";

export default function Users() {
  const dispatch = useAppDispatch();
  const { users, isUsersLoading, search, page } = useAppSelector(
    (state) => state.users,
  );
  const { role } = useAuth();

  useEffect(() => {
    if (role === "PM") {
      dispatch(getUsersAction({ role, search: search!, page }));
    } else {
      dispatch(getUsersAction({ search: search!, page: page!, role }));
    }
  }, [search, page]);

  return (
    <div className="container py-10 mx-auto">
      <UsersPageHeader />
      {isUsersLoading ? (
        <TableSkeleton />
      ) : (
        <DataTable columns={columns} data={users} />
      )}
    </div>
  );
}
