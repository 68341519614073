import { LogoCropedPng } from "@/assets";
import { useTranslation } from "react-i18next";
import styles from "./auth-form-header.module.scss";

const AuthFormHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.authFormHeader}>
      <img className={styles.authFormLogo} src={LogoCropedPng} alt="Logo png" />

      <h1 className={styles.authFormTitle}>{t("login_title")}</h1>

      <p className={styles.authFormDescription}>{t("login_description")}</p>
    </div>
  );
};

export default AuthFormHeader;
