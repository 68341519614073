import { WhitePlusSvg } from "@/assets";
import { Button, ButtonProps } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import classNames from "classnames";
import { Dispatch, FC, SetStateAction } from "react";

interface Props extends ButtonProps {
  text?: string;
  formBody?: () => JSX.Element;
  modalIsOpen?: boolean;
  setModalIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const AddButton: FC<Props> = ({
  text,
  formBody,
  modalIsOpen,
  setModalIsOpen,
  title,
  ...rest
}) => {
  return (
    <>
      {" "}
      <Button
        onClick={() => setModalIsOpen?.(true)}
        className={classNames("h-[45px]", rest.className)}
        {...rest}
      >
        <img src={WhitePlusSvg} alt="Add, Plus icon" />
        <span>{text}</span>
      </Button>
      <Dialog
        open={modalIsOpen}
        onOpenChange={(e) => setModalIsOpen?.(e)}
        modal
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <div className="max-h-[70vh] overflow-y-auto px-[24px] pb-[24px]">
            {formBody?.()}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddButton;
