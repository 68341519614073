import { Button } from "@/components/ui/button";
import { FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { createLoginAndPassword } from "../../lib/helpers/createLoginPassword";

const LoginPasswordField = ({
  form,
  isUpdate,
}: {
  form: any;
  isUpdate?: boolean;
}) => {
  const { t } = useTranslation();

  const generateData = () => {
    const { login, password } = createLoginAndPassword(
      form.getValues("phone"),
      form.getValues("full_name"),
    );

    if (!isUpdate) {
      form.setValue("username", login);
    }
    form.setValue("password", password);
  };

  return (
    <>
      <FormField
        control={form.control}
        name="username"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("enter_login")}*</FormLabel>
            <Input disabled={isUpdate} {...field} />
          </FormItem>
        )}
      />
      <div>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("password")}*</FormLabel>
              <Input {...field} />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button
            onClick={generateData}
            type="button"
            className="text-gray-500"
            variant={"link"}
          >
            {t("generate")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LoginPasswordField;
