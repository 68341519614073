import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";

import { XCloseSvg } from "@/assets";
import { cn } from "@/lib/utils";

const DrawerDialog = DialogPrimitive.Root;

const DrawerDialogTrigger = DialogPrimitive.Trigger;

const DrawerDialogPortal = DialogPrimitive.Portal;

const DrawerDialogClose = DialogPrimitive.Close;

const DrawerDialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
));
DrawerDialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DrawerDialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DrawerDialogPortal>
    <DrawerDialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "fixed right-0 top-0 z-50 grid w-full max-w-lg gap-4 overflow-y-auto border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-right-[50%] data-[state=open]:slide-in-from-right-[50%] sm:rounded-lg",
        className,
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DrawerDialogPortal>
));
DrawerDialogContent.displayName = DialogPrimitive.Content.displayName;

const DrawerDialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-row items-center justify-between space-y-1.5 border-b border-b-border p-5 text-center text-[18px] sm:text-left",
      className,
    )}
    {...props}
  >
    <h2 className="font-semibold">{props.children}</h2>

    <DialogPrimitive.Close className="right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
      <img src={XCloseSvg} className="h-[24px] w-[24px]" />
      <span className="sr-only">Close</span>
    </DialogPrimitive.Close>
  </div>
);
DrawerDialogHeader.displayName = "DrawerDialogHeader";

const DrawerDialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);
DrawerDialogFooter.displayName = "DrawerDialogFooter";

const DrawerDialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
DrawerDialogTitle.displayName = DialogPrimitive.Title.displayName;

const DrawerDialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
DrawerDialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  DrawerDialog,
  DrawerDialogClose,
  DrawerDialogContent,
  DrawerDialogDescription,
  DrawerDialogFooter,
  DrawerDialogHeader,
  DrawerDialogOverlay,
  DrawerDialogPortal,
  DrawerDialogTitle,
  DrawerDialogTrigger,
};
