import TableSkeleton from "@/components/common/table-skeleton/table-skeleton";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useEffect } from "react";
import { getAllProjectsAction } from "../../store/projects.actions";
import ClientProjectItem from "../client-project-item/client-project-item";
import ClientProjectsPageHeader from "../client-projects-page-header";

const ClientProjectsPageComponent = () => {
  const {
    projects,
    isProjectsLoading,
    page,
    projectsSearch,
    projectsFromTo,
    projectsStatusFilter,
  } = useAppSelector((state) => state.projects);

  const dispacth = useAppDispatch();

  useEffect(() => {
    dispacth(
      getAllProjectsAction({
        role: "client",
        page,
        search: projectsSearch,
        fromTo: projectsFromTo,
        status: projectsStatusFilter,
      }),
    );
  }, []);

  console.log(projects);

  return (
    <div className="container py-10 mx-auto">
      <ClientProjectsPageHeader />

      <div className="grid gap-4 mt-8 xs:grid-cols-1 md:grid-cols-2">
        {isProjectsLoading ? (
          <TableSkeleton />
        ) : (
          projects?.map((item) => <ClientProjectItem key={item.id} {...item} />)
        )}
      </div>
    </div>
  );
};

export default ClientProjectsPageComponent;
