import { IStatusTypes } from "@/components/common/selectable-status/selectable-status";

const useStatusStyles = (status: IStatusTypes) => {
  const classes = () => {
    switch (status) {
      case "default":
        return "bg-gray-100 text-gray-600 hover:bg-gray-200";
      case "pending":
        return "bg-amber-100 text-amber-600 hover:bg-amber-200";
      case "rejected":
        return "bg-red-100 text-red-600 hover:bg-red-200";
      case "success":
        return "bg-green-100 text-green-600 hover:bg-green-200";
      case "in_developing":
        return "bg-blue-100 text-blue-500 hover:bg-blue-200";
      default:
        return "bg-gray-100 text-gray-600 hover:bg-gray-200";
    }
  };

  return classes();
};

export default useStatusStyles;
