import ClientProjects from "@/pages/projects-client/projects-client";
import Projects from "@/pages/projects/projects";
import TasksPage from "@/pages/tasks-page/tasks-page";
import Users from "@/pages/users/users";
import { Navigate } from "react-router-dom";

export const protectedRoutes = ({
  user,
  role,
}: {
  user: any | null;
  role: string;
}) => {
  return [
    {
      path: "/profile",
      element: user ? (
        <Navigate to={`/profile/${user.id}`} replace />
      ) : (
        <Navigate to="/login?redirect=/profile" replace />
      ),
    },
    {
      path: "/",
      element: user ? (
        role === "client" ? (
          <Navigate to="/projects-client" replace />
        ) : (
          <Navigate to="/projects" replace />
        )
      ) : (
        <Navigate to="/login?redirect=/" replace />
      ),
    },
    {
      path: "/users",
      element:
        role !== "client" && user ? (
          <Users />
        ) : (
          <Navigate to="/login?redirect=/users" replace />
        ),
    },
    {
      path: "/projects",
      element: user ? (
        <Projects />
      ) : (
        <Navigate to="/login?redirect=/projects" replace />
      ),
    },
    {
      path: "/projects-client",
      element:
        role === "client" && user ? (
          <ClientProjects />
        ) : (
          <Navigate to="/login?redirect=/projects" replace />
        ),
    },
    {
      path: "/tasks",
      element: user ? (
        <TasksPage />
      ) : (
        <Navigate to="/login?redirect=/tasks" replace />
      ),
    },
    {
      path: "/project/:id",
      element: user ? (
        <TasksPage />
      ) : (
        <Navigate to="/login?redirect=/tasks" replace />
      ),
    },
  ];
};
