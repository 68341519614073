import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Input } from "@/components/ui/input";
import { getFileFormat } from "@/lib/get-file-format";
import { uploadImages } from "@/services/file/file.service";
import { IFile } from "@/types";
import { ReloadIcon } from "@radix-ui/react-icons";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFile } from "react-icons/ai";
import { BiFile } from "react-icons/bi";
import "react-photo-view/dist/react-photo-view.css";

interface Props {
  images?: string[];
  onChange?: (images: string[]) => void;
  initialImages?: string[];
}

const ImageUploader: FC<Props> = ({ onChange, initialImages }) => {
  const [images, setImages] = useState<string[]>(initialImages || []);
  const [_, setContextMenuItem] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const response = await uploadImages(e.target.files!).finally(() => {
      setLoading(false);
    });
    const imgs = [...images, ...response?.map((item: IFile) => item.file)];
    setImages(imgs);
    onChange?.(imgs);
  };

  const removeImage = async (imageUrl: string) => {
    setImages(images.filter((item) => item !== imageUrl));
    onChange?.(images.filter((item) => item !== imageUrl));
  };

  useEffect(() => {
    onChange?.(images);
  }, []);

  useEffect(() => {
    if (initialImages?.length) {
      setImages(initialImages);
    }
  }, [initialImages]);

  return (
    <div>
      <label>
        <div className="flex h-[80px] w-full cursor-pointer items-center justify-center rounded-lg border border-dashed border-gray-400 bg-gray-100 text-gray-400 hover:bg-gray-200">
          <div className="flex h-[50px] w-[50px] items-center justify-center">
            {loading ? (
              <ReloadIcon className="h-6 w-6 animate-spin" />
            ) : (
              <BiFile className="text-4xl" />
            )}
          </div>
          {t("file_upload")}
        </div>
        <Input
          disabled={loading}
          multiple
          onChange={uploadFile}
          type="file"
          className="hidden"
        />
      </label>

      <div className="mt-4 grid grid-cols-4 gap-2">
        {images.map((item) => {
          return getFileFormat(item) === "image" ? (
            <ContextMenu
              key={item}
              onOpenChange={(open) => {
                if (open) {
                  setContextMenuItem(item);
                } else {
                  setContextMenuItem(null);
                }
              }}
            >
              <ContextMenuTrigger>
                <div
                  className="relative h-[100px] w-full cursor-pointer rounded-md border border-border p-1 transition duration-200 hover:border-primary"
                  key={item}
                >
                  <img
                    className={`h-full w-full rounded-md object-cover transition duration-200`}
                    src={item}
                  />
                </div>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuItem
                  onClick={() => {
                    removeImage(item);
                  }}
                >
                  Удалить
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          ) : (
            <ContextMenu
              key={item}
              onOpenChange={(open) => {
                if (open) {
                  setContextMenuItem(item);
                } else {
                  setContextMenuItem(null);
                }
              }}
            >
              <ContextMenuTrigger>
                <a
                  href={item}
                  download={item}
                  className="relative flex h-[100px] w-full cursor-pointer flex-col items-center justify-center rounded-md border border-border p-1 text-center transition duration-200 hover:border-primary"
                >
                  <AiOutlineFile className="text-4xl text-primary" />

                  <a
                    className="text-primary hover:underline"
                    href={item}
                    download={item}
                  >
                    Download
                  </a>
                </a>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuItem
                  onClick={() => {
                    removeImage(item);
                  }}
                >
                  Удалить
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          );
        })}
      </div>
    </div>
  );
};

export default ImageUploader;
