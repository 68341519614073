import SelectableStatus from "@/components/common/selectable-status/selectable-status";
import { FC } from "react";
import { Link } from "react-router-dom";
import {
  projectStatuses,
  projectStatusesArray,
} from "../../lib/data/project.statuses";
import { IProject } from "../../types";

interface Props extends IProject {}

const ClientProjectItem: FC<Props> = ({
  id,
  title,
  start_date,
  project_manager,
  end_date,
  project_status,
}) => {
  return (
    <Link
      to={`/project/${id}`}
      className="active:opactiy-60 rounded-[12px] border border-border transition duration-200 hover:shadow-md"
    >
      <header className="flex items-center justify-between rounded-t-[12px] border-b border-b-border bg-cardHeaderBg px-6 py-4">
        <h2 className="text-lg">{title}</h2>

        <SelectableStatus
          readonly
          text={projectStatuses[project_status].text}
          status={projectStatuses[project_status].status}
          // @ts-ignore
          menuItems={projectStatusesArray.map((item) => ({
            value: item.value,
            label: item.label,
          }))}
        />
      </header>

      <section className="flex items-center justify-between p-4 mb-4">
        <div className="flex flex-col items-start gap-1">
          <span className="text-sm text-grayText">Руководитель</span>
          <h2 className="text-lg font-medium">{project_manager?.full_name}</h2>
        </div>
        <div className="flex flex-col items-end gap-1">
          <span className="text-sm text-grayText">Дедлайн проекта</span>
          <h2 className="text-lg font-medium">
            {start_date} - {end_date}
          </h2>
        </div>
      </section>
    </Link>
  );
};

export default ClientProjectItem;
