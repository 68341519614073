import { authSlice } from "@/features/auth";
import { projectsSlice } from "@/features/projects";
import { tasksSlice } from "@/features/tasks";
import { usersSlice } from "@/features/users";
import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./ui/ui.slice";

export const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    users: usersSlice,
    projects: projectsSlice,
    tasks: tasksSlice,
  },
  devTools: false,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
