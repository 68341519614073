import { FilterSvg } from "@/assets";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

const FilterBar = () => {
  const { t } = useTranslation();

  return (
    <Button variant={"outline"} className="h-[45px]">
      <img src={FilterSvg} alt="Filter svg" />

      <span>{t("filter")}</span>
    </Button>
  );
};

export default FilterBar;
