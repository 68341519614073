import {
  createTaskUrl,
  getAdminTasksUrl,
  getArchiveTasks,
  getClientTasksUrl,
  getConversionDataUrl,
  getPMTasksUrl,
  updateTaskUrl,
} from "@/api/api.constants";
import axiosInstance from "@/api/api.interceptor";
import { FromToType } from "@/features/projects/store/projects.slice";
import { IGetRequestData } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IConversionType,
  ICreateTaskData,
  ITask,
  ITasksResponse,
  IUpdateTaskData,
} from "../types";

interface IGetTasksData extends IGetRequestData {
  project_id: string | number;
  statuses?: string;
  date?: FromToType;
}
export const getAdminTasks = createAsyncThunk<ITasksResponse, IGetTasksData>(
  "tasks/getAdminTasks",
  async ({ project_id, statuses, date, search }, thunkAPI) => {
    try {
      const res = await axiosInstance.get<ITasksResponse>(
        getAdminTasksUrl(project_id, statuses!, date, search),
      );

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAdminArchiveTasks = createAsyncThunk<
  ITasksResponse,
  IGetTasksData
>("tasks/getAdminArchiveTasks", async ({ project_id, statuses }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<ITasksResponse>(
      getArchiveTasks(project_id, statuses!),
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getClientArchiveTasks = createAsyncThunk<
  ITasksResponse,
  IGetTasksData
>("tasks/getClientArchiveTasks", async ({ project_id, statuses }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<ITasksResponse>(
      getArchiveTasks(project_id, statuses!),
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getPMTasks = createAsyncThunk<ITasksResponse, IGetRequestData>(
  "tasks/getPMTasks",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(getPMTasksUrl());
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getClientTasks = createAsyncThunk<ITasksResponse, IGetTasksData>(
  "tasks/getClientTasks",
  async ({ project_id, statuses, date, search }, thunkAPI) => {
    try {
      const res = await axiosInstance.get(
        getClientTasksUrl(project_id, statuses, date, search),
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createTaskAction = createAsyncThunk<
  ITask,
  { data: ICreateTaskData; project_id: string | number; role?: string }
>("tasks/create", async (data, thunkApi) => {
  try {
    const res = await axiosInstance.post(
      createTaskUrl(data.project_id!),
      data.data,
    );
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const updateTaskAction = createAsyncThunk<
  ITask,
  { data: IUpdateTaskData; task_id: string | number }
>("tasks/update", async (data, thunkApi) => {
  try {
    const res = await axiosInstance.patch(
      updateTaskUrl(data.task_id!),
      data.data,
    );
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fileUploadAction = createAsyncThunk<
  ITasksResponse,
  { data: IUpdateTaskData; id: string | number }
>("tasks/create", async (data, thunkApi) => {
  try {
    const res = await axiosInstance.patch(updateTaskUrl(data.id), data.data);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getConversionData = createAsyncThunk<
  IConversionType,
  { projectId: number | string; fromTo: FromToType }
>("tasks/conversion", async ({ projectId, fromTo }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<IConversionType>(
      getConversionDataUrl(projectId, fromTo),
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
