import { createUserUrl, getUsersUrl, updateUserUrl } from "@/api/api.constants";
import axiosInstance from "@/api/api.interceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateUserType,
  IUpdateUserType,
  IUser,
  IUserResponse,
} from "../types/users.types";

export const getUsersAction = createAsyncThunk<
  IUserResponse,
  { search: string; page?: number; role?: string }
>("users/list", async ({ search, page, role }, thunkAPI) => {
  try {
    const res = await axiosInstance.get<IUserResponse>(
      getUsersUrl(search, page!, role),
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createUserAction = createAsyncThunk<
  IUser,
  { data: ICreateUserType; role?: string }
>("users/create", async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(
      createUserUrl(data.role?.toLowerCase()),
      {
        ...data.data,
        phone_number: data.data.phone,
      },
    );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const updateeUserAction = createAsyncThunk<
  IUser,
  { data: IUpdateUserType; id: number }
>("users/update", async ({ data, id }, thunkAPI) => {
  try {
    const res = await axiosInstance.patch(updateUserUrl(id), {
      ...data,
      phone_number: data.phone,
    });
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
