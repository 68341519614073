import { FilterSvg } from "@/assets";
import { DateRangePicker } from "@/components/common/date-range-picker/date-range-picker";
import { Button } from "@/components/ui/button";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import MultiSelect from "@/components/common/multi-select/multi-select";
import SearchBar from "@/components/common/search-bar/search-bar";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useDebounce } from "@/hooks/use-debounce";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  setAdminTaskDateFilter,
  setAdminTaskSearch,
  setAdminTaskStatusFilter,
  setClientTaskDateFilter,
  setClientTaskSearch,
  setClientTaskStatusFilter,
} from "../../store/tasks.slice";
import { adminTaskStatuses, clientTaskStatuses } from "../../types";

interface Props {
  isClient?: boolean;
}

const TasksFilterPopover: React.FC<Props> = ({ isClient }) => {
  const [_, setDate] = useState("01.06 - 20.07.2024");
  const {
    adminTasksStatusFilter,
    clientTasksStatusFilter,
    adminTaskDateFilter,
    clientTaskDateFilter,
  } = useAppSelector((state) => state.tasks);
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isClient) {
      dispatch(setClientTaskSearch(debouncedSearch));
    } else {
      dispatch(setAdminTaskSearch(debouncedSearch));
    }
  }, [isClient, debouncedSearch]);

  // change date filter
  const changeDataFilter = (from: string, to: string) => {
    if (isClient) {
      dispatch(
        setClientTaskDateFilter({
          from,
          to,
        }),
      );
    } else {
      dispatch(
        setAdminTaskDateFilter({
          from,
          to,
        }),
      );
    }
  };

  // change status filter
  const changeStatusFilter = (data: string[]) => {
    if (isClient) {
      dispatch(setClientTaskStatusFilter(data));
    } else {
      dispatch(setAdminTaskStatusFilter(data));
    }
  };

  return (
    <Popover className="seven-step relative">
      {({}) => (
        <>
          <PopoverButton>
            <Button variant={"ghost"}>
              <img src={FilterSvg} aria-keyshortcuts="Filter svg" />
              {t("filter")}
            </Button>
          </PopoverButton>
          <PopoverPanel className="absolute right-0 z-10 mt-2 w-80 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t("search")}
                </label>
                <div className="relative">
                  <SearchBar
                    onChange={setSearch}
                    value={search}
                    className="xs:w-full md:w-full"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t("status")}
                </label>
                <div className="relative">
                  <MultiSelect
                    onChange={changeStatusFilter}
                    value={
                      isClient
                        ? clientTasksStatusFilter
                        : adminTasksStatusFilter
                    }
                    options={(!isClient
                      ? adminTaskStatuses
                      : clientTaskStatuses
                    ).map((item) => ({
                      value: item.value,
                      label: t(item.label),
                    }))}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t("date")}
                </label>
                <div className="relative">
                  <DateRangePicker
                    value={
                      !isClient
                        ? [adminTaskDateFilter.from, adminTaskDateFilter.to]
                        : [clientTaskDateFilter.from, clientTaskDateFilter.to]
                    }
                    setValue={(data) => {
                      changeDataFilter(
                        moment(data[0]!).format("YYYY-MM-DD"),
                        moment(data[1]!).format("YYYY-MM-DD"),
                      );
                    }}
                  />
                </div>
              </div>
              <div className="my-2 mb-4 w-full border-t border-t-border" />
              <div className="grid grid-cols-2">
                <Button
                  onClick={() => {
                    setDate("");
                    changeDataFilter("", "");
                    changeStatusFilter(
                      isClient
                        ? ["send_for_confirmation", "confirmed"]
                        : ["new", "reject", "in_developing"],
                    );
                  }}
                  className="mr-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {t("cancel")}
                </Button>
                <Button className="rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700">
                  {t("save")}
                </Button>
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default TasksFilterPopover;
