import { createSlice } from "@reduxjs/toolkit";

interface IUiSliceInitialState {
  sidebarIsOpen: boolean;
}

const initialState: IUiSliceInitialState = {
  sidebarIsOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      const result =
        typeof action.payload === "undefined"
          ? !state.sidebarIsOpen
          : action.payload;
      state.sidebarIsOpen = result;
      localStorage.setItem("sidebar", result);
    },
  },
});

export const { toggleSidebar } = uiSlice.actions;
export default uiSlice.reducer;
