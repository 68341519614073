import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConversionType, ITask } from "../types";
import { FromToType } from "./../../projects/store/projects.slice";
import {
  createTaskAction,
  getAdminArchiveTasks,
  getAdminTasks,
  getClientArchiveTasks,
  getClientTasks,
  getConversionData,
  getPMTasks,
  updateTaskAction,
} from "./tasks.action";

type TasksDrawerContentType = "view" | "update";
type SelectedTaskIdType = string | number | null;

interface IInitialState {
  isAdminTasksLoading: boolean;
  isClientTasksLoading: boolean;
  adminTasks: ITask[];
  clientTasks: ITask[];
  isTaskCreating: boolean;
  tasksDrawerContent: TasksDrawerContentType;
  selectedTaskId: SelectedTaskIdType;
  adminArchiveTasks: ITask[];
  clientArchiveTasks: ITask[];
  adminTasksStatusFilter: string[];
  clientTasksStatusFilter: string[];
  adminTaskDateFilter: FromToType;
  clientTaskDateFilter: FromToType;
  adminTaskSearch: string;
  clientTaskSearch: string;
  conversion: IConversionType | null;
  isConversionLoading: boolean;
  conversionDateFilter: FromToType;
}

const initialState: IInitialState = {
  isAdminTasksLoading: false,
  adminTasks: [],
  isTaskCreating: false,
  tasksDrawerContent: "view",
  selectedTaskId: null,
  clientTasks: [],
  isClientTasksLoading: false,
  adminArchiveTasks: [],
  clientArchiveTasks: [],
  adminTasksStatusFilter: ["new", "reject", "in_developing"],
  clientTasksStatusFilter: ["send_for_confirmation", "confirmed"],
  adminTaskDateFilter: { from: "", to: "" },
  clientTaskDateFilter: { from: "", to: "" },
  adminTaskSearch: "",
  clientTaskSearch: "",
  conversion: null,
  isConversionLoading: false,
  conversionDateFilter: { from: "", to: "" },
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasksDrawerContent: (
      state,
      action: PayloadAction<TasksDrawerContentType>,
    ) => {
      state.tasksDrawerContent = action.payload;
    },
    setSelectedTaskId: (state, action: PayloadAction<SelectedTaskIdType>) => {
      state.selectedTaskId = action.payload;
    },
    setAdminTaskStatusFilter: (state, action: PayloadAction<string[]>) => {
      state.adminTasksStatusFilter = action.payload;
    },
    setClientTaskStatusFilter: (state, action: PayloadAction<string[]>) => {
      state.clientTasksStatusFilter = action.payload;
    },
    setAdminTaskDateFilter: (state, action: PayloadAction<FromToType>) => {
      state.adminTaskDateFilter = action.payload;
    },
    setClientTaskDateFilter: (state, action: PayloadAction<FromToType>) => {
      state.clientTaskDateFilter = action.payload;
    },
    setAdminTaskSearch: (state, action: PayloadAction<string>) => {
      state.adminTaskSearch = action.payload;
    },
    setClientTaskSearch: (state, action: PayloadAction<string>) => {
      state.clientTaskSearch = action.payload;
    },
    setConversionFromTo: (state, action: PayloadAction<FromToType>) => {
      state.conversionDateFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAdminTasks.pending, (state) => {
        state.isAdminTasksLoading = true;
      })
      .addCase(getAdminTasks.fulfilled, (state, action) => {
        state.adminTasks = action.payload.results;
        state.isAdminTasksLoading = false;
      })
      .addCase(getAdminTasks.rejected, (state) => {
        state.isAdminTasksLoading = false;
      })
      .addCase(getConversionData.pending, (state) => {
        state.isConversionLoading = true;
      })
      .addCase(
        getConversionData.fulfilled,
        (state, action: PayloadAction<IConversionType | null>) => {
          state.conversion = action.payload;
          state.isConversionLoading = false;
        },
      )
      .addCase(getConversionData.rejected, (state) => {
        state.isConversionLoading = false;
      })
      .addCase(getClientTasks.pending, (state) => {
        state.isClientTasksLoading = true;
      })
      .addCase(getClientTasks.fulfilled, (state, action) => {
        state.clientTasks = action.payload.results;
        state.isClientTasksLoading = false;
      })
      .addCase(getClientTasks.rejected, (state) => {
        state.isClientTasksLoading = false;
      })
      .addCase(getAdminArchiveTasks.pending, (state) => {
        state.isAdminTasksLoading = true;
      })
      .addCase(getAdminArchiveTasks.fulfilled, (state, action) => {
        state.adminArchiveTasks = action.payload.results;
        state.isAdminTasksLoading = false;
      })
      .addCase(getAdminArchiveTasks.rejected, (state) => {
        state.isAdminTasksLoading = false;
      })
      .addCase(getClientArchiveTasks.pending, (state) => {
        state.isClientTasksLoading = true;
      })
      .addCase(getClientArchiveTasks.fulfilled, (state, action) => {
        state.clientArchiveTasks = action.payload.results;
        state.isClientTasksLoading = false;
      })
      .addCase(getClientArchiveTasks.rejected, (state) => {
        state.isClientTasksLoading = false;
      })
      .addCase(getPMTasks.pending, (state) => {
        state.isAdminTasksLoading = true;
      })
      .addCase(getPMTasks.fulfilled, (state, action) => {
        state.adminTasks = action.payload.results;
        state.isAdminTasksLoading = false;
      })
      .addCase(getPMTasks.rejected, (state) => {
        state.isAdminTasksLoading = false;
      })
      .addCase(createTaskAction.pending, (state) => {
        state.isTaskCreating = true;
      })
      .addCase(
        createTaskAction.fulfilled,
        (state, action: PayloadAction<ITask>) => {
          state.isTaskCreating = false;
          state.adminTasks = [action.payload, ...state.adminTasks];
        },
      )
      .addCase(createTaskAction.rejected, (state) => {
        state.isTaskCreating = false;
      })
      .addCase(updateTaskAction.pending, (state) => {
        state.isTaskCreating = true;
      })
      .addCase(
        updateTaskAction.fulfilled,
        (state, action: PayloadAction<ITask>) => {
          state.isTaskCreating = false;
          if (action.payload.archive) {
            state.adminTasks = state.adminTasks.filter(
              (item) => item.id !== action.payload.id,
            );

            state.clientTasks = state.clientTasks.filter(
              (item) => item.id !== action.payload.id,
            );

            return;
          }

          if (
            action.payload.task_status === "send_for_confirmation" ||
            action.payload.task_status === "confirmed"
          ) {
            state.adminTasks = state.adminTasks.filter(
              (item) => item.id !== action.payload.id,
            );
            const oldIndex = state.clientTasks.findIndex(
              (x) => x.id === action.payload.id,
            );

            if (oldIndex === -1) {
              state.clientTasks = [action.payload, ...state.clientTasks];
            } else {
              state.clientTasks = state.clientTasks.map((item) => {
                if (item.id === action.payload.id) {
                  return action.payload;
                } else {
                  return item;
                }
              });
            }

            return;
          }

          state.clientTasks = state.clientTasks.filter(
            (item) => item.id !== action.payload.id,
          );
          const oldIndex = state.adminTasks.findIndex(
            (x) => x.id === action.payload.id,
          );

          if (oldIndex === -1) {
            state.adminTasks = [action.payload, ...state.adminTasks];
          } else {
            state.adminTasks = state.adminTasks.map((item) => {
              if (item.id === action.payload.id) {
                return action.payload;
              } else {
                return item;
              }
            });
          }

          state.tasksDrawerContent = "view";
        },
      )
      .addCase(updateTaskAction.rejected, (state) => {
        state.isTaskCreating = false;
      });
  },
});

export const {
  setTasksDrawerContent,
  setSelectedTaskId,
  setAdminTaskStatusFilter,
  setClientTaskStatusFilter,
  setAdminTaskDateFilter,
  setClientTaskDateFilter,
  setAdminTaskSearch,
  setClientTaskSearch,
  setConversionFromTo,
} = tasksSlice.actions;
export default tasksSlice.reducer;
