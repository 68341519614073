import PageTitle from "@/components/common/page-title/page-title";

const ClientProjectsPageHeader = () => {
  return (
    <div className="flex flex-wrap justify-between gap-4 xs:flex-col xs:items-start lg:flex-row lg:items-center">
      <PageTitle title="Проекты" isBack={false} />
      <div className="flex items-stretch gap-3 xs:w-full xs:flex-col lg:w-auto lg:flex-row"></div>
    </div>
  );
};

export default ClientProjectsPageHeader;
