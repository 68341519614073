import ImageUploader from "@/components/common/image-uploader/image-uploader";
import RichTextEditor from "@/components/common/rich-text-editor/rich-text-editor";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { createTaskSchema } from "../../lib/validators/tasks.validator";
import { createTaskAction } from "../../store/tasks.action";
import { ITask } from "../../types";

interface Props {
  initialState?: ITask;
  onCompleted?: () => void;
  errors?: any[];
}

export function CreateTaskForm({ initialState, onCompleted, errors }: Props) {
  const { isTaskCreating } = useAppSelector((state) => state.tasks);
  const { role } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const params = useParams();
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof createTaskSchema>>({
    resolver: zodResolver(createTaskSchema),
    defaultValues: {},
  });

  function onSubmit(values: any) {
    dispatch(
      createTaskAction({
        data: values,
        project_id: params.id!,
        role,
      }),
    ).then((res) => {
      if (res.type === "tasks/create/fulfilled") {
        toast({
          title: t("task_added"),
          className: "bg-green-500 text-white",
        });
        onCompleted?.();
      }
    });
  }

  function setError() {
    if (errors?.length) {
      errors.map((item: any) => {
        form.setError(item, {
          message: "Error",
        });
      });
    }
  }

  useEffect(() => {
    setError();
  }, [errors]);

  useEffect(() => {
    if (initialState) {
      Object.keys(initialState).map((item: any) => {
        // @ts-ignore
        form.setValue(item, initialState[item]);
      });
    }
  }, [initialState]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("name_task")}*</FormLabel>
              <FormControl>
                <Input placeholder={t("name_task")} {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("task_description")}*</FormLabel>
              <FormControl>
                <RichTextEditor
                  setValue={(data) => form.setValue("description", data)}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="file_paths"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("file_upload")}</FormLabel>
              <FormControl>
                <ImageUploader
                  images={field.value}
                  onChange={(images) => form.setValue("file_paths", images)}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button isLoading={isTaskCreating} className="w-full" type="submit">
          {t("submit")}
        </Button>
      </form>
    </Form>
  );
}
