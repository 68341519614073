import CustomSelect from "@/components/common/custom-select/custom-select";
import { DateRangePicker } from "@/components/common/date-range-picker/date-range-picker";
import ImageUploader from "@/components/common/image-uploader/image-uploader";
import MultiSelect from "@/components/common/multi-select/multi-select";
import RichTextEditor from "@/components/common/rich-text-editor/rich-text-editor";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/features/auth";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { useDebounce } from "@/hooks/use-debounce";
import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { createProjectSchema } from "../../lib/validators/projects.validator";
import {
  createProjectAction,
  getAllProjectsAction,
  getClients,
  getProjectManagers,
  getProjectTypes,
} from "../../store/projects.actions";
import { IProject } from "../../types";

interface Props {
  initialState?: IProject;
  onCompleted?: () => void;
  errors?: any[];
}

export function CreateProjectForm({
  initialState,
  onCompleted,
  errors,
}: Props) {
  const [searchPM, setSearchPM] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const { isCreatingProject, clients, projectManagers, projectTypes } =
    useAppSelector((state) => state.projects);
  const { role } = useAuth();
  const dispatch = useAppDispatch();

  const debouncedSearchPM = useDebounce(searchPM, 300);
  const debouncedSearchClient = useDebounce(searchClient, 300);

  const { t } = useTranslation();

  const { toast } = useToast();

  const form = useForm<z.infer<typeof createProjectSchema>>({
    resolver: zodResolver(createProjectSchema),
    defaultValues: {},
  });

  function onSubmit(values: any) {
    dispatch(createProjectAction({ data: values, role })).then((res) => {
      if (res.type === "projects/create/fulfilled") {
        toast({
          title: t("project_created"),
          className: "text-white bg-green-500",
        });
        dispatch(getAllProjectsAction({ role }));
        onCompleted?.();
      } else {
        toast({
          title: t("something_went_wrong"),
          className: "text-white bg-red-500",
        });
      }
    });
  }

  function setError() {
    if (errors?.length) {
      errors.map((item: any) => {
        form.setError(item, {
          message: "The username already exist!",
        });
      });
    }
  }

  useEffect(() => {
    setError();
  }, [errors]);

  useEffect(() => {
    if (initialState) {
      Object.keys(initialState).map((item: any) => {
        // @ts-ignore
        form.setValue(item, initialState[item]);
      });
    }
  }, [initialState]);

  useEffect(() => {
    dispatch(getClients({ search: debouncedSearchClient, role }));
  }, [debouncedSearchClient, role]);

  useEffect(() => {
    dispatch(getProjectManagers({ search: debouncedSearchPM }));
  }, [debouncedSearchPM]);

  useEffect(() => {
    dispatch(getProjectTypes());
  }, []);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_name")}*</FormLabel>
              <FormControl>
                <Input placeholder={t("project_name")} {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="project_type_ids"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_type")}*</FormLabel>
              <FormControl>
                <MultiSelect
                  onChange={(data) => form.setValue("project_type_ids", data)}
                  value={field.value}
                  options={projectTypes.map((item) => ({
                    label: item.title,
                    value: String(item.id),
                  }))}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {role === "admin" && (
          <FormField
            control={form.control}
            name="project_manager"
            render={() => (
              <FormItem>
                <FormLabel>{t("project_manager")}*</FormLabel>
                <FormControl>
                  <CustomSelect
                    primaryColor="primary"
                    onChange={(data: any) =>
                      form.setValue("project_manager", data)
                    }
                    value={form.getValues("project_manager")}
                    options={projectManagers.map((item) => ({
                      label: item.full_name,
                      value: String(item.id),
                    }))}
                    onSearchInputChange={(e: any) => {
                      setSearchPM(e.target.value);
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="client"
          render={() => (
            <FormItem>
              <FormLabel>{t("client")}*</FormLabel>
              <FormControl>
                <CustomSelect
                  primaryColor="primary"
                  onChange={(data: any) => form.setValue("client", data)}
                  value={form.getValues("client")}
                  options={clients.map((item) => ({
                    label: item.full_name,
                    value: String(item.id),
                  }))}
                  onSearchInputChange={(e: any) => {
                    setSearchClient(e.target.value);
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="start_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("project_deadline")}*</FormLabel>
              <FormControl>
                <DateRangePicker
                  {...field}
                  value={[
                    form.getValues("start_date"),
                    form.getValues("end_date"),
                  ]}
                  setValue={(data) => {
                    form.setValue(
                      "start_date",
                      moment(data[0]).format("YYYY-MM-DD"),
                    );
                    form.setValue(
                      "end_date",
                      moment(data[1]).format("YYYY-MM-DD"),
                    );
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("comment")}*</FormLabel>
              <FormControl>
                <RichTextEditor
                  setValue={(data) => form.setValue("description", data)}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="file_paths"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("file_upload")}</FormLabel>
              <FormControl>
                <ImageUploader
                  images={field.value}
                  onChange={(images) => form.setValue("file_paths", images)}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button isLoading={isCreatingProject} className="w-full" type="submit">
          {t("submit")}
        </Button>
      </form>
    </Form>
  );
}
