import { useAppSelector } from "./store-hooks";

const useSidebarCollapse = () => {
  const { sidebarIsOpen } = useAppSelector((state) => state.ui);

  return {
    width: sidebarIsOpen ? "250px" : "100px",
    minWidth: sidebarIsOpen ? "250px" : "100px",
    maxWidth: sidebarIsOpen ? "250px" : "100px",
  };
};

export default useSidebarCollapse;
